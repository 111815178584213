<div class="contaner" id="login-section">

	<div class="screen">
		<div class="screen__content">
			<div class="text-center">
				<img class="text-center mt-3" src="assets/images/armoirie.png" routerLink="/workspace" alt="Image"
					width="" height="80px" />
			</div>

			<form class="login">
				<h6 class="text-center">Authentification</h6>
				<div class="login__field" style="text-align: center" [hidden]="spinner">
					<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
				</div>
				<div class="alert alert-danger alert-dismissible fade show" role="alert" [hidden]="erreur">
					<small>Echec de l'authentification !</small>
					<button type="button" class="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="login__field">
					<i class="login__icon pi pi-user"></i>
					<input name="username" [(ngModel)]="username" type="text" class="login__input"
						placeholder="Matricule" required>
				</div>
				<div class="login__field">
					<i class="login__icon pi pi-lock"></i>
					<input name="password" [(ngModel)]="password" type="password" class="login__input"
						placeholder="Mot de passe" required>
				</div>
				<br>

				<div class="form-check">
					<input name="rememberMe" [(ngModel)]="rememberMe" class="form-check-input" type="checkbox">
					<label class="form-check-label" for="rememberMe">
						Se souvenir de moi
					</label>
				</div>
				<div class="mt-3 text-right">
					<label>
						<a style="color: #019e48;" routerLink="/mot-de-passe-oublier"> Mot de passe oublié</a>
					</label>
				</div>
				<button class="button login__submit text-center" (click)="logIn()">
					<span class="button__text">Se connecter</span>
					<!--<i class="button__icon pi pi-lock-open"></i>-->
				</button>
			</form>
		</div>
		<div class="screen__background">
			<span class="screen__background__shape screen__background__shape4"></span>
			<span class="screen__background__shape screen__background__shape3"></span>
			<span class="screen__background__shape screen__background__shape2"></span>
			<span class="screen__background__shape screen__background__shape1"></span>
		</div>
	</div>
</div>