<!-- l'entete de page -->
<div class="shadow-sm cesw-section cesw-section-img-tree cesw-section-img-contact card texte-banner ">
    <div class="container">
        <div class="page-header">
            <div>
                <h1 class="page-title">Les textes règlementaires</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/"><i class="zmdi zmdi-home zmdi-hc-lg"></i></a></li>
                    <li class="breadcrumb-item active" aria-current="page"><i
                            style="margin-top: -10px;font-size: 20px;margin-right: 5px;" aria-hidden="true"
                            class="fa fa-home"></i>
                        <p-breadcrumb [model]="items"></p-breadcrumb>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<!-- le corps de la page -->

<div class="container">
    <div class="grid" style="background-color: #f5f5f500 !important; width:700;margin-top: 50px; margin-bottom: 50px;">
        <div class="col-12 md:col-12 lg:col-3 mt-5 mb-2 p-3"
            style="border: 2px solid #eaeaea;border-radius: 14px;background: linear-gradient(180deg, #f6f6f6, #ffffff);">
            <div>
                <h4> Rechercher</h4>
            </div>

            <div>
                <div class="p-ml-auto">
                    <p-message *ngIf="message" [severity]="message.severity" [text]="message.summary"></p-message>
                </div>
                <form #dtf="ngForm">

                    <div class="form-group">
                        <label for="numero"><b>Type :</b></label>
                        <p-dropdown [options]="lesTypes" name="type" [(ngModel)]="search.type"
                            placeholder="Selectionner le type de document" rows="4" inputId="type" [filter]="true"
                            filterBy="libelle" [showClear]="true">
                        </p-dropdown>
                    </div>

                    <div class="form-group">
                        <label for="anne"><b>Année :</b></label>
                        <input id="anne" class="form-control" name="anne" [(ngModel)]="search.anne"
                            placeholder="Annee de document" pInputText required />
                    </div>

                    <div class="form-group">
                        <label for="numero"><b>Numéro :</b></label>
                        <input id="numero" name="numero" class="form-control" [(ngModel)]="search.numero"
                            placeholder="numéro de document" pInputText required />
                    </div>

                    <!-- <div class="form-group">
                        <label for="secteurs"><b>Secteurs :</b></label>
                        <p-dropdown id="secteurs" [options]="secteurs" name="secteur" optionLabel="libelle"
                            optionValue="libelle" #secteur="ngModel" [(ngModel)]="search.secteur"
                            placeholder="Selectionner le secteur d'activité" rows="4" inputId="secteur" [filter]="true"
                            filterBy="libelle" [showClear]="true">
                        </p-dropdown>
                    </div> -->
                    <p-divider></p-divider>
                    <div>
                        <span>
                            <button pButton (click)="searchDocument()" [disabled]="!search.type" label="Rechecher"
                                class="p-button-success"></button>
                            <p-progressSpinner *ngIf="isOpInProgress" strokeWidth="4"
                                [style]="{width: '50px', height: '50px'}">
                            </p-progressSpinner>
                        </span>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 md:col-12 lg:col-9 mt-5">

            <p-table #dt [value]="lesDocuments" [paginator]="true" [rows]="recordsPerPage" (onLazyLoad)="load($event)"
                [totalRecords]="totalRecords"
                [globalFilterFields]="['reference','date','reference','secteur','type','description']"
                currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
                [showCurrentPageReport]=true [rowsPerPageOptions]="[10, 20,50,100]" styleClass="p-datatable-sm">

                <ng-template pTemplate="caption">
                    <div class="row">
                        <!-- titre -->
                        <div class="col" style="padding: 8px; margin-left: 8px">
                            <span>L'ensemble des textes règlementaires adoptés au Burkina</span>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">

                </ng-template>

                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-document>
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="document">
                        <td>{{document.description}} </td>
                        <td style="width:15%">
                            <div class="row">
                                <div class="col-12 md:col-12 lg:col-6">
                                    <button (click)="onDownload(document.fileName)" pButton pRipple
                                        icon="pi pi-download" pTooltip="Voir document" tooltipPosition="bottom"
                                        class="p-button-rounded p-button-success p-mr-2"></button>
                                </div>
                                <div class="col-12 md:col-12 lg:col-6">
                                    <button (click)="onInfo(document)" pButton pRipple icon="pi pi-eye"
                                        pTooltip="Voir détails" tooltipPosition="bottom"
                                        class="p-button-rounded p-button-warning p-mr-2"></button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <!-- tpsm -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td style="text-align: center;">{{emptyTableMessage}}</td>
                    <tr> 
                </ng-template>
                <!--  -->
            </p-table>
        </div>
    </div>

    <!-- details -->

    <p-dialog [(visible)]="documentDetail" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
        [style]="{width: '50vw'}">
        <ng-template pTemplate="header">

            <span class="p-mr-auto"> DETAILS DOCUMENT</span>
        </ng-template>
        <div class="row">
            <div class="col-md-12">
                <ul class="list-group list-group-flush">

                    <li class="list-group-item list-group-item-action">
                        <span class="va-m"><span class="font-weight-bold">Type : </span>
                            {{document.type?.libelle}}</span>
                    </li>
                    <li class="list-group-item list-group-item-action">
                        <span class="va-m"><span class="font-weight-bold">reference : </span>
                            {{document.reference}}</span>
                    </li>
                    <li class="list-group-item list-group-item-action">
                        <span class="va-m"><span class="font-weight-bold">Date : </span> {{document.date |
                            date:'dd/MM/yyyy'}}</span>
                    </li>

                    <!-- <li class="list-group-item list-group-item-action">
                        <span class="va-m"><span class="font-weight-bold">Secteur : </span>
                            {{document.secteur?.libelle}}</span>
                    </li> -->
                    <li class="list-group-item list-group-item-action">
                        <span class="va-m"><span class="font-weight-bold">Objet : </span>
                            {{document.description}}</span>
                    </li>
                    <!-- <li class="list-group-item list-group-item-action">
                        <span class="va-m"><span class="font-weight-bold">
                                JournalOfficiel:
                               </span> {{document.journalOfficiel?.numero}} du {{document.journalOfficiel?.dateJournal |
                            date:'dd/MM/yyyy'}} - {{document.journalOfficiel?.natureJournal }}- <a type="button"
                                pTooltip="visiter le journal officiel"
                                href="{{document.journalOfficiel?.url}}">{{document.journalOfficiel?.url}}</a></span>
                    </li> -->
                    <li class="list-group-item list-group-item-action">
                        <span class="va-m"><span class="font-weight-bold">Document parent : </span>
                            {{document.parent?.reference}} </span>
                    </li>

                </ul>
            </div>

        </div>
        <p-divider></p-divider>
        <div class="p-ml-auto text-right">
            <button description="reset" label="Fermer" (click)="documentDetail=false"
                class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
        </div>
    </p-dialog>
</div>
