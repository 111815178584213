import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-public',
  templateUrl: './footer-public.component.html',
  styleUrls: ['./footer-public.component.scss']
})
export class FooterPublicComponent implements OnInit {
  currentYear: number = 0;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    
      this.getCurrentYear();

  }

  getCurrentYear() {
    this.currentYear = new Date().getFullYear();
  }

  accueil(){
    this.router.navigate(["/accueil"]);
  }

  contacter(){
    this.router.navigate(["/accueil/contactezNous"]);
  }
}
