<div>
    <button pButton pRipple label="Créer"  class="p-button-success mr-2"
      [disabled]="!enableCreate" (click)="fireCreate()">
    </button>
    
    <button pButton pRipple label="Programmer"  class="p-ml-3"
      [disabled]="!enablePro" (click)="firePro()">
    </button>
    <!-- <button pButton label="Supprimer" icon="pi pi-trash" class="p-button-raised p-button-text p-button-danger"
      [disabled]="!enableDelete" (click)="fireDelete()">
    </button> -->
    
  </div>