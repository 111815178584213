<div>
  <p-card>
  <!-- ToolBar -->

    <div class="p-d-flex">
      <app-crud-toolbar [enableCreate]="enableCreate" (create)="onCreate()">
      </app-crud-toolbar>
      <div class="p-ml-auto">
        <p-message *ngIf="message" [severity]="message.severity" [text]="message.summary"></p-message>
        <p-progressSpinner *ngIf="isOpInProgress" strokeWidth="4" [style]="{width: '50px', height: '50px'}">
        </p-progressSpinner>
      </div>
    </div>
      <p-divider></p-divider>

      <p-table #dt [value]="journalOfficiels" [paginator]="true" [rows]="recordsPerPage" [loading]="isLoading"
          (onLazyLoad)="chargerJournal($event)" [totalRecords]="totalRecords"
          [globalFilterFields]="['numero','dateJournal','natureJournal', 'url','referenceJournal']"
          currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
          [showCurrentPageReport]=true [rowsPerPageOptions]="[10, 20]" styleClass="p-datatable-sm">

          <ng-template pTemplate="caption">
              <div class="row">
                  <!-- titre -->
                  <div class="col" style="padding: 8px; margin-left: 8px">
                      <span>Liste des Journaux Officiels</span>
                  </div>

                  <!-- volet recherche -->
                  <div class="col text-right">
                      <span class="p-input-icon-left">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text"
                              (input)="dt.filterGlobal($any($event.target)!.value, 'contains')"
                              placeholder="Recherche..." />
                      </span>
                  </div>
              </div>
          </ng-template>

          <ng-template pTemplate="header">
              <tr>
                  <th pSortableColumn="numero">Numéro
                      <p-sortIcon field="numero"></p-sortIcon>
                  </th>

                  <th pSortableColumn="dateJournal">Date
                      <p-sortIcon field="dateJournal"></p-sortIcon>
                  </th>
                  <th pSortableColumn="natureJournal">Nature
                    <p-sortIcon field="natureJournal"></p-sortIcon>
                </th>
                <!-- <th pSortableColumn="url">url
                  <p-sortIcon field="url"></p-sortIcon>
              </th> -->
              <!-- <th pSortableColumn="referenceJournal">reference Journal
                <p-sortIcon field="referenceJournal"></p-sortIcon>
            </th> -->

                  <th>
                      Actions
                  </th>
              </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-journalOffiel>
              <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="journalOffiel">
                  <td>{{journalOffiel.numero}} </td>
                  <td>{{journalOffiel.dateJournal | date: 'dd/MM/yyyy'}} </td>
                  <td>{{journalOffiel.natureJournal}} </td>
                  <!-- <td>{{journalOffiel.url}} </td>
                  <td>{{journalOffiel.referenceJournal}} </td> -->
                  <td class="td-toolbar">

                      <app-actions-toolbal-iud
                          (info)="onInfo(journalOffiel)" [enableBtnInfo]="enableBtnInfo"
                          (edit)="onEdit(journalOffiel)" [enableBtnEdit]="enableBtnEdit"
                          (delete)="onDelete(journalOffiel)" [enableBtnDelete]="enableBtnDelete">
                      </app-actions-toolbal-iud>

                  </td>
              </tr>
          </ng-template>
      </p-table>
  </p-card>

<!-- formulaire d'ajout et de modification -->

<p-dialog [(visible)]="showDialog" [modal]="true" [maximizable]="true" appendTo="body"
  [style]="{width: '50vw'}" [contentStyle]="{height: '600px'}"
  [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
  <ng-template pTemplate="header">
    <i class="{{isEditing() ? 'pi-pencil' : 'pi-plus'}} pi p-mr-1"></i>
    <span class="mr-auto">
      {{journalOfficiel.id ? 'Modifier' : 'Ajouter'}} un Journal
    </span>
  </ng-template>
  <p-progressBar *ngIf="isDialogOpInProgress" mode="indeterminate"></p-progressBar>
  <p-message *ngIf="dialogErrorMessage" severity="error" [text]="dialogErrorMessage"></p-message>
  <p-divider></p-divider>
  <!-- Form -->

  <form (ngSubmit)="save()" #dtf="ngForm">

    <div class="p-fluid grid">
      <div class="p-fluid p-col-12 col-md-12">
        <label for="numero">Numéro<span class="text-danger">*</span> :</label>
        <input id="numero" name="numéro" #numero="ngModel" [(ngModel)]="journalOfficiel.numero" pInputText required />
      </div>

      <div class="p-fluid p-col-12 col-md-12">
        <label for="date">Date<span class="text-danger">*</span> :</label>
        <p-calendar [(ngModel)]="journalOfficiel.dateJournal " dateFormat="dd-mm-yy" name="dateJournal" id="dateJournal"
        [showIcon]="true" inputId="icon"></p-calendar>
        <!-- <label for="date">Date :</label>
        <input id="date" name="date" #date="ngModel" [(ngModel)]="journalOfficiel.date" pInputText/> -->
      </div>

      <div class="p-fluid p-col-12 col-md-12">
        <label for="nature">Nature<span class="text-danger">*</span> :</label>
        <p-dropdown [options]="natureJournals" optionValue="libelle" optionLabel="libelle" [(ngModel)]="journalOfficiel.natureJournal" id="natureJournal" name="natureJournal" placeholder="Selectionner la nature"  row="4" imputId="NatureJournal" [filter]="true" filterBy="label" [showClear]="true"></p-dropdown>
        <!-- <input id="natureJournal" name="natureJournal" #date="ngModel" [(ngModel)]="journalOfficiel.natureJournal" pInputText/>  -->
      </div>

      <div class="p-fluid p-col-12 col-md-12">
        <label for="url">Url<span class="text-danger">*</span> :</label>
        <input id="url" name="url" #url="ngModel" [(ngModel)]="journalOfficiel.url" pInputText required />
      </div>
      <!-- <div class="p-fluid p-col-12 col-md-12">
        <label for="referenceJournal">reference Journal :</label>
        <input id="referenceJournal" name="referenceJournal" #referenceJournal="ngModel" [(ngModel)]="journalOfficiel.referenceJournal" pInputText required />
      </div> -->

    </div>


    <p-divider></p-divider>
    <div class="p-ml-auto text-right">
      <button description="reset" label="Annuler" (click)="showDialog=false"
        class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
      <button description="submit" [disabled]="!dtf.form.valid" label="{{isEditing() ? 'Enregistrer' : 'Ajouter'}}"
        icon="pi {{isEditing() ? 'pi-save' : 'pi-plus'}}"
        class="p-button-raised p-button-text {{isEditing() ? 'p-button-primary' : 'p-button-success'}}"
        pButton></button>
    </div>
  </form>
</p-dialog>

<!-- formulaire d'affichage du détail -->


  <p-dialog [(visible)]="journalOfficielDetail" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">

      <span class="p-mr-auto"> DETAILS JOURNAL OFFICIEL</span>
    </ng-template>
    <div class="row">
      <div class="col-md-12">
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Numéro:</span>  {{journalOfficiel.numero}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
           <span class="va-m"><span class="font-weight-bold">Date:</span>  {{journalOfficiel.dateJournal | date: 'dd/MM/yyyy'}}</span>
         </li>
         <li class="list-group-item list-group-item-action">
          <span class="va-m"><span class="font-weight-bold">Nature:</span>  {{journalOfficiel.natureJournal}}</span>
        </li>
      <!-- <li class="list-group-item list-group-item-action">
       <span class="va-m"><span class="font-weight-bold">Url:</span>  {{journalOfficiel.url}}</span>
     </li> -->
    <!-- <li class="list-group-item list-group-item-action">
     <span class="va-m"><span class="font-weight-bold">reference Journal:</span>  {{journalOfficiel.referenceJournal}}</span>
   </li> -->

        </ul>
     </div>

    </div>
 <p-divider></p-divider>
 <div class="p-ml-auto text-right">
     <button description="reset" label="Fermer" (click)="journalOfficielDetail=false" class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
 </div>
</p-dialog>

<!-- Confirmation -->
<app-confirmation></app-confirmation>
</div>
