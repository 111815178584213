import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  Exemple, GetAllExempleResponse } from 'src/app/models/parametrage/exemple';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const resourceUrl = environment.exempleResource;
@Injectable({
  providedIn: 'root'
})
export class ExempleService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<GetAllExempleResponse> {
    return this.http.get(`assets/data/exemple.json`, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let exemplesResponse: GetAllExempleResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          exemples: response.body as Exemple[]
        };
        return exemplesResponse;
      }));
  }

  create(request: Exemple): Observable<Exemple> {
    return this.http.post(resourceUrl, request);
  }

  update(exemple: Exemple): Observable<Exemple> {
    return this.http.put(resourceUrl, exemple);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${resourceUrl}/${id}`);
  }
}
