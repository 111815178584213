import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { Message } from 'primeng/api/message';
import { Secteurs } from 'src/app/models/parametrage/secteurs';
import { SecteursService } from 'src/app/services/parametrage/secteurs.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './../../../../services/parametrage/authentication.service';

@Component({
  selector: 'app-secteurs',
  templateUrl: './secteurs.component.html',
  styleUrls: ['./secteurs.component.scss']
})
export class SecteursComponent implements OnInit {

  @ViewChild('dtf') form!: NgForm;

  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  secteurs!: Secteurs[];
  secteur: Secteurs= {};
  enableCreate = true;
  enableBtnInfo = true;
  enableBtnEdit = true;
  enableBtnDelete=true;
  isLoading!: boolean;
  isOpInProgress!: boolean;
  isDialogOpInProgress!: boolean;
  showDialog = false;
  exempleDetail: boolean=false;
  message: any;
  dialogErrorMessage: any;
  parentSecteurs!: Secteurs[];
  permissions: any;
  constructor(
    private secteursService: SecteursService,
    private confirmationService: ConfirmationService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.permissions=this.authService.getPrivilege();
    this.enableBtnDelete=this.authService.checkPermission(this.permissions, ['ROLE_ADMIN']);
    this.chargerSecteurs();
    this.getParentSecteur();
  }

  isEditing() {
    return !!this.secteur.id;
  }
 // Affichage

  chargerSecteurs(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.secteursService.getAllSecteurs().subscribe(response => {
      this.isLoading = false;
      this.secteurs = response.listeSecteurs;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  //Creation

  save() {
    if (this.secteur.id) {
      this.edit();
    } else {
      this.create();
    }
  }

  getParentSecteur(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.secteursService.getAllSecteurs().subscribe(response => {
      this.isLoading = false;
      this.parentSecteurs = response.listeSecteurs;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
 
  onCreate() {
    this.secteur = {};
    this.clearDialogMessages();
    this.form.resetForm();
    this.showDialog = true;
  }

  create() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
    this.secteursService.create(this.secteur).subscribe(response => {
      if (this.secteurs.length !== this.recordsPerPage) {
        this.secteurs.push(response);
        this.secteurs = this.secteurs.slice();
      }
      this.totalRecords++;
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'Le secteurs créé avec succès' });
    }, error => this.handleError(error));
  }
  
  //Détail
  onInfo(selection: any) {
    this.secteur = Object.assign({}, selection);
    this.clearDialogMessages();
    this.exempleDetail=true;
  }

    // Edit

    onEdit(selection: any) {
      this.secteur = Object.assign({}, selection);
      this.clearDialogMessages();
      this.showDialog = true;
    }
  
    edit() {
      this.clearDialogMessages();
      this.isDialogOpInProgress = true;
      this.secteursService.update(this.secteur).subscribe(response => {
        let index = this.secteurs.findIndex(secteur => secteur.id === response.id);
        this.secteurs[index] = response;
        this.isDialogOpInProgress = false;
        this.showDialog = false;
        this.showMessage({ severity: 'success', summary: 'secteur modifié avec succès' });
      }, error => this.handleError(error));
    }

    
    // Deletion

  onDelete(selection: any) {
    this.confirmationService.confirm({
      message: 'Etes-vous sur de vouloir supprimer cet secteur?',
      accept: () => {
        this.delete(selection);
      }
    });
  }

  delete(selection: any) {
    this.isOpInProgress = true;
    this.secteursService.delete(selection.id).subscribe(() => {
      this.secteurs = this.secteurs.filter(secteur => secteur.id !== selection.id);
      selection = null;
      this.isOpInProgress = false;
      this.totalRecords--;
      this.showMessage({
        severity: 'success',
        summary: 'secteur supprimé avec succès',
      });
    },(error) => this.handleError(error)
  );
  }
    // Errors

    handleError(error: HttpErrorResponse) {
      console.error(`Processing Error: ${JSON.stringify(error)}`);
      this.isDialogOpInProgress = false;
      this.dialogErrorMessage = error.error.title;
    }
     // Messages

  clearDialogMessages() {
    this.dialogErrorMessage = null;
  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }

}
