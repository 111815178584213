import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from 'src/app/services/parametrage/document.service';
import { Document } from 'src/app/models/parametrage/document';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfirmationService, LazyLoadEvent, Message } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { Publicite } from 'src/app/models/parametrage/publicite';
import { PubliciteService } from 'src/app/services/parametrage/publicite.service';
interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: 'app-publicite',
  templateUrl: './publicite.component.html',
  styleUrls: ['./publicite.component.scss']
})
export class PubliciteComponent implements OnInit {
  @ViewChild('dtf') form!: NgForm;
  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  publicites!: Publicite[];
  publicite: Publicite= {};
 // types!: TypeDocument[];
  //secteurs!: Secteurs[];
  //journalOfficiels!:JournalOfficiel[];
  enableCreate = true;
  enableBtnInfo = true;
  enableBtnEdit = true;
  enableBtnDelete=true;
  enableBtnDownload=true;
  isLoading!: boolean;
  isOpInProgress!: boolean;
  isDialogOpInProgress!: boolean;
  showDialog = false;
  documentDetail: boolean=false;
  message: any;
  dialogErrorMessage: any;
  file: Blob | string = '';
  isDisplay = true;
  constructor(
    //private documentService: DocumentService,
    private publiciteService:PubliciteService,
    private confirmationService: ConfirmationService
  ) { }

  isEditing() {
    return !!this.publicite.url;
  }

   // Affichage

   load(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.publiciteService.getAll().subscribe(response => {
      this.isLoading = false;
      console.warn("liste des publicites",this.publicites);
      this.publicites = response.publicites;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
  ngOnInit(): void {
    this.load();
  }

  onDownload(fileName :string){
    console.log(fileName);
    let fileUrl;
    this.publiciteService.download(fileName).subscribe(blob =>  {
      fileUrl = window.URL.createObjectURL(blob);
      console.warn("imageUrl",fileUrl);
      window.open(fileUrl);
    //  fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
       console.error("url", fileUrl);
       this.showMessage({ severity: 'success', summary: 'image téléchargée avec succès' });
    });

    // this.documentService.download(fileName).subscribe(blob => saveAs(blob, fileName));
    //   console.log("======",documentUrl+'download/'+this.fiches.fileName)
    // window.open(documentUrl+'download/'+this.fiches.fileName,  '_blank');
    //  this.pdfload=true

   }

  save() {
    if (this.publicite.id) {
      console.warn("BON");
      this.edit();
    } else {
      console.warn("BAD");
      this.create();
    }
  }

  edit() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
    this.publiciteService.update(this.publicite).subscribe(response => {
      let index = this.publicites.findIndex(publicite => publicite.url === response.url);
      this.publicites[index] = response;
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'image modifiée avec succès' });
    }, error => this.handleError(error));
  }
 //Détail
 onInfo(selection: any) {
  this.publicite = Object.assign({}, selection);
  this.clearDialogMessages();
  this.documentDetail=true;
}

  // Edit

  onEdit(selection: any) {
    this.publicite = Object.assign({}, selection);
    this.clearDialogMessages();
    this.showDialog = true;
  }

  // Deletion

  onDelete(selection: any) {
    this.confirmationService.confirm({
      message: 'Etes-vous sur de vouloir supprimer cette image?',
      accept: () => {
        this.delete(selection);
      }
    });
  }

  delete(selection: any) {
    this.isOpInProgress = true;
    this.publiciteService.delete(selection.id).subscribe(() => {
      this.load();
      this.isOpInProgress = false;
      this.totalRecords--;
      this.showMessage({
        severity: 'success',
        summary: 'Image supprimée avec succès',
      });
    },(error) => this.handleError(error)
  );
  }
  onCreate() {
    this.publicite = {};
    this.clearDialogMessages();
    this.form.resetForm();
    this.showDialog = true;
  }

  create() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
   /* const formData: FormData = new FormData();
    const fichesAsJson: Blob = new Blob([JSON.stringify(this.publicite)], { type: 'application/json' });
    formData.append('imagePublicitaireDTO', fichesAsJson);
    formData.append('imagePublicitaireFile', this.file);

    console.log(this.publicite);
    console.warn("FORM DATA",formData);*/
    // console.log(formData.get('documentFile'));

    console.warn("0000",this.publicite);
    this.publiciteService.save(this.publicite).subscribe(response => {
     this.load();
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'Image enregistrée avec succès' });
    }, error => this.handleError(error));

  }

    // Errors

    handleError(error: HttpErrorResponse) {
      console.error(`Processing Error: ${JSON.stringify(error)}`);
      this.isDialogOpInProgress = false;
      this.dialogErrorMessage = error.error.title;
    }

    onSelectFile(event:any): void {
      // console.log(event.files[0]);
      // console.log(event.files[0].name);
      let file:File = event.files[0];
      this.file = file;
      // this.document.fileName =  event.files[0].name;
      // this.document.fileSize = event.files[0].size;
    }

     // Messages

     clearDialogMessages() {
      this.dialogErrorMessage = null;
    }

    showMessage(message: Message) {
      this.message = message;
      this.timeoutHandle = setTimeout(() => {
        this.message = null;
      }, 5000);
    }


  onUpload(event: UploadEvent) {
    for (let file of event.files) {
      if (file) {
        console.warn("info file",file);
        this.publiciteService.upload(file).subscribe({
          next: (response) => {
            //Mise a jour de l'objet publicite
            console.warn("response",response.body);
            this.publicite.image = response.body.image;
            this.publicite.url = response.body.url;
            this.publicite.local_url = response.body.url;
          },
          error: (error) => {
            console.error("error" + JSON.stringify(error));

            this.showMessage({severity: 'error', summary: error.error.message});
          }
        });
      }
    }
  }

  async voirPiece(filname?: string): Promise<void> {
    console.warn("fi",filname);
    if (filname) {
      const link = await this.publiciteService.visualiser(
        filname
      );
      if (link) {
        window.open(link, '_blank');
      }
    }
  }

  display(){
    this.isDisplay = true;
  }
}
