import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { PubliciteService } from 'src/app/services/parametrage/publicite.service';
import { ContactezNous } from 'src/app/models/parametrage/contactezNous';

@Component({
  selector: 'app-contactez-nous',
  templateUrl: './contactez-nous.component.html',
  styleUrls: ['./contactez-nous.component.scss']
})
export class ContactezNousComponent implements OnInit {


  @ViewChild('dtf') form!: NgForm;

  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;

  enableCreate = true;
  enableMinistereStructure = false;
  enableBtnInfo = true;
  enableBtnEdit = true;
  enableBtnDelete = true;
  isLoading!: boolean;
  isOpInProgress!: boolean;
  isDialogOpInProgress!: boolean;
  showDialog = false;
  showDialog2 = false;
  structureDetail: boolean = false;
  isSuccessful: boolean = false;
  message: any;
  dialogErrorMessage: any;
  contactezNous: ContactezNous = {};
  contactNous!: ContactezNous[];
  public items: MenuItem[] = [];

  constructor(
    private confirmationservice: ConfirmationService,
    private publiciteService: PubliciteService
  ) { }

  ngOnInit(): void {
    this.items = [
      { label: 'LegiBurkina', routerLink: ['/accueil'], },
      { label: 'Contact', routerLink: ['/accueil/contactezNous'], },

    ];
  }

  onCreate() {
    this.contactezNous = {};
    this.clearDialogMessages();
    this.form.resetForm();
    this.showDialog = true;
  }

  create() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
    const data = {
      nom_prenom: this.contactezNous.nom_prenom,
      subject: this.contactezNous.subject,
      content: this.contactezNous.content,
      contact: this.contactezNous.contact,
      email: this.contactezNous.email,
      isMultipart: 'false',
      isHtml: 'false'
    }
    console.log("message", data)
    this.publiciteService.saveMessage(data).subscribe(
      (response) => {
        if (this.contactNous.length !== this.recordsPerPage) {
          this.contactNous.push(response);
          this.contactNous = this.contactNous.slice();
        }
        this.totalRecords++;
        this.isDialogOpInProgress = false;
        this.showDialog = false;
        this.showMessage({
          severity: 'success',
          summary: 'message envoyé au SGG-CM',
        });
      },
      (error) => this.handleError(error)
    );
  }

  clearDialogMessages() {
    this.dialogErrorMessage = null;
  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }

  // Errors

  handleError(error: HttpErrorResponse) {
    console.error(`Processing Error: ${JSON.stringify(error)}`);
    this.isDialogOpInProgress = false;
    this.dialogErrorMessage = error.error.message;
  }
}
