<div>
  <button pButton label="Ajouter" icon="pi pi-plus" class="p-button-raised p-button-text p-button-success mr-2"
    [disabled]="!enableCreate" (click)="fireCreate()">
  </button>
  <button pButton label="Changer Ministère" icon="pi pi-pencil" class="p-button-raised p-button-text p-button-primary mr-2"
    [disabled]="!enableEdit" (click)="fireEdit()">
  </button>
  <!-- <button pButton label="Supprimer" icon="pi pi-trash" class="p-button-raised p-button-text p-button-danger"
    [disabled]="!enableDelete" (click)="fireDelete()">
  </button> -->

</div>
