<!--<div class="row" style="border:2px solid silver ;">

  <div class="p-fluid p-col-12 col-lg-2">
    <div class="form-group">
        <label for="anne"><b>Annee :</b></label>
    <input id="anne" name="anne" class="form-control" #anne="ngModel" [(ngModel)]="search.anne"
        placeholder="anne" pInputText />
    </div>

  </div>
  <div class="p-fluid p-col-12 col-lg-2">
    <label for="mois"><b>Mois :</b></label>
    <input id="mois" name="mois" class="form-control" #mois="ngModel" [(ngModel)]="search.mois"
        placeholder="mois" pInputText />
  </div>
  <div class="p-fluid p-col-12 col-lg-2">
    <label for="jour"><b>Jour :</b></label>
    <input id="jour" name="jour" class="form-control" #jour="ngModel" [(ngModel)]="search.jour"
            placeholder="jour" pInputText />
  </div>
 <div class="p-fluid p-col-12 col-lg-2 mt-4">
    <button pButton type="submit" label="Rechercher" (click)="getNbDowload(search)" class="p-button-success"></button>
</div>
</div>-->

<div class="row mt-5">
    <div class="p-fluid p-col-12 col-lg-6">

        <div class="text-center" style=" background-color: #089e48;
        height: auto;
        border-radius: 20px;
        border: 3px solid #fee780;
        color: #ffffff;
        padding: 10px;
        box-shadow: inset 0px 0px 20px rgb(0 0 0 / 27%);    margin: 7px;">
            <i class="fa fa-user fa-3x" style="    border-radius: 199px;
            padding: 20px;
            font-size: 27px;
            width: 70px;
            height: 70px;
            background-color: #fee78066;"></i>
            <h6 style="margin-top: 10px;text-align: center;color:#ffbe06">Nombre d'utilisateurs</h6>
            <h5 style="margin-top: 20px; text-align: center;color:#ffffff;">{{this.aUser}}</h5>
        </div>
    </div>
    <div class="p-fluid p-col-12 col-lg-6">
        <div class="text-center" style=" background-color: #d20f13;
        height: auto;
        border-radius: 20px;
        border: 3px solid #fee780;
        color: #ffffff;
        padding: 10px;
        box-shadow: inset 0px 0px 20px rgb(0 0 0 / 27%);    margin: 7px;">
            <i class="fa fa-file fa-3x" style="    border-radius: 199px;
            padding: 20px;
            font-size: 27px;
            width: 70px;
            height: 70px;
            background-color: #fee78066;"></i>

            <h6 style="margin-top: 10px;text-align: center;color:#ffbe06">Nombre de documents</h6>
            <h5 style="margin-top: 20px; text-align: center;color:#ffffff;">{{this.aDoc}}</h5>
        </div>
    </div>
</div>



<div class="row mt-5" style="text-align: center">

    <div class="p-fluid p-col-12 col-lg-12">
        <br>
        <h6 style="margin-top: 10px; margin-bottom: 10px;">Evolution des documents téléversés par types de document
        </h6>
        <br>
        <p-card>
            <!-- <p-chart type="line" [data]="globalLinesData" [options]="basicOptions"></p-chart> -->
            <p-chart type="bar" [data]="data" [options]="basicOptions"></p-chart>
        </p-card>
    </div>

    <!--<div class="p-fluid p-col-12 col-lg-6">
        <br>
        <h6 style="margin-top: 10px; margin-bottom: 10px;">Evolution des documents téléchargés par mois</h6>
        <br>
        <p-card>
            &lt;!&ndash; <p-chart type="line" [data]="globalLinesData" [options]="basicOptions"></p-chart> &ndash;&gt;
            <p-chart type="bar" [data]="dataMensuel" [options]="basicOptions"></p-chart>
        </p-card>
    </div>-->
</div>

<div class="row mt-5" style="text-align: center">

    <div class="p-fluid p-col-12 col-lg-12">

        <br>
        <h6 style="margin-top: 10px; margin-bottom: 10px;">Evolution des téléchargements de document par types
        </h6>
        <br>
        <p-card>

            <div class="container" style="padding: 20px;
            background-color: #fdd1163b;
            border-radius: 15px;
            border: 1px solid #d6d6d6;margin-bottom: 30px;">
                <h6 class="text-center">Filtre</h6>
                <div class="row mt-2" style="margin-bottom: 19px; ">
                    <div class="p-fluid p-col-12 col-lg-5 col-md-5 col-sm-12 filter-contain-mobile">
                        <p-dropdown [options]="typeDocuments" [(ngModel)]="searchStateFilter.type" optionLabel="libelle"
                            optionValue="libelle" placeholder="Selectionner une type"></p-dropdown>
                    </div>
                    <div class="p-fluid p-col-12 col-lg-5 col-md-4 col-sm-12 filter-contain-mobile">
                        <p-dropdown [options]="yearsFromDb" [(ngModel)]="searchStateFilter.anne"
                            placeholder="Selectionner une Année"></p-dropdown>
                    </div>
                    <div class="p-fluid p-col-12 col-lg-2 col-md-3 col-sm-12 filter-contain-mobile">
                        <button description="Stat" (click)="getNbDowloadbyType(false)" label="Charger"
                            class="p-button-raised p-button-text p-button-success mr-2" pButton></button>

                    </div>

                </div>
            </div>




            <!-- <p-chart type="line" [data]="globalLinesData" [options]="basicOptions"></p-chart> -->
            <p-chart type="bar" [data]="downloadData" [options]="options"></p-chart>
        </p-card>
    </div>

    <!--<div class="p-fluid p-col-12 col-lg-6">
      <br>
      <h6 style="margin-top: 10px; margin-bottom: 10px;">Evolution des documents téléchargés par mois</h6>
      <br>
      <p-card>
          &lt;!&ndash; <p-chart type="line" [data]="globalLinesData" [options]="basicOptions"></p-chart> &ndash;&gt;
          <p-chart type="bar" [data]="dataMensuel" [options]="basicOptions"></p-chart>
      </p-card>
  </div>-->
</div>
