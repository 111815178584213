import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetAllDocumentResponse, Document } from 'src/app/models/parametrage/document';
import { Search, Search2 } from 'src/app/models/parametrage/search';
import { DocumentSearch, GlobalSearch } from './../../models/vo/document-search';
import {Publicite} from "../../models/parametrage/publicite";
import {StateDoc} from "../../models/parametrage/statDoc";
const resourceUrl = environment.documentResource;
const resourceGFDUrl= environment.globalSearchDocRessource;
const ressourceFDUrl=environment.searchDocRessource;
const ressourceFDAutresUrl=environment.searchDocAutresRessource;

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<GetAllDocumentResponse> {
    // return this.http.get(`assets/data/document.json`, { observe: 'response' })
    return this.http.get(resourceUrl, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let documentsResponse: GetAllDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          documents: response.body as Document[]
        };
        return documentsResponse;
      }));
  }



  getDocumentRecent(){
    return this.http.get(resourceUrl+'/plus-recent', { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let documentsResponse: GetAllDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          documents: response.body as Document[]
        };
        return documentsResponse;
      }));
  }

  getAllByType(type: string){
    return this.http.get(`${resourceUrl}/type/${type}`, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let documentsResponse: GetAllDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          documents: response.body as Document[]
        };
        return documentsResponse;
      }));
  }

  filterDocument(search: Search) : Observable<any> {
    return this.http.post(ressourceFDUrl, search);
  }
  filterRapport(search: Search2) : Observable<any> {
    return this.http.post(resourceUrl+'/search-num-anne-mj', search);
  }

  filterAutresDocument(search: Search) : Observable<any> {
    return this.http.post(ressourceFDAutresUrl, search);
  }

  filterByKeyWord(search: Search,page: number, size: number) : Observable<any> {
    let params: HttpParams = new HttpParams();
    // Set page and size
    params = page ? params.set('page', page) : params;
    params = size ? params.set('size', size) : params;
    return this.http.post(resourceUrl+'/search-mot-cle', search,{ params: params,observe:"response"});
  }

  globalfilterDocument(search: GlobalSearch) : Observable<any> {
    return this.http.post(resourceGFDUrl, search);
  }

  create(request: any): Observable<Document> {
    return this.http.post(resourceUrl, request);
  }

  upload(file: File): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    return this.http.post<Document>(`${resourceUrl}/upload-document`, formData, { observe: 'response' });
  }

  async visualiser(fileName: string):Promise<string>{
    console.warn("filePPP",fileName);
    const resp = await this.getFile(fileName).toPromise().catch(e=>{
      console.warn("ERROR",e.message());
    });
    if(resp){
      const file = new Blob([resp],{type: 'application/pdf'});
      return URL.createObjectURL(file);
    }else{
      return  '';
    }
  }

  private getFile(fileName: string):Observable<any>{
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
    };

    const formData: FormData = new FormData();
    formData.append('fileName',fileName);
    return this.http.post(`${resourceUrl}/fichier/recuperer-document`,formData,httpOptions);
  }

  // download(request: string): Observable<any> {
  //   return this.http.post(resourceUrl+'/download', request );
  // }

  // download(request: string): any {
  //   return this.http.post(resourceUrl+'/download', request , {responseType: 'blob'});
  // }


  // download(url:string):Observable<Blob>
  // {
  //   // return this.http.get(resourceUrl+'download/'+name,{responseType: 'blob'});
  //   return this.http.post(resourceUrl+'/download', "C:\\Users\\HP\\Legiburkina-files\\TYPE1\\ingenieur-trav-info-genie-logiciel.pdf" , {responseType: 'blob'});
  // }

  download(url:string):Observable<Blob>
  {
    return this.http.get(resourceUrl+'/download/'+url,{responseType: 'blob'});
  }

  update(document: Document): Observable<Document> {
    return this.http.put(resourceUrl+'/update', document);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${resourceUrl}/${id}`);
  }

  getTotalDocByType(): Observable<HttpResponse<StateDoc[]>> {
    return this.http.get<StateDoc[]>(`${resourceUrl}/statistique/document-by-type`, { observe: 'response' });
  }

  getCountByTypeDocument(): Observable<{ [key: string]: number }> {
    return this.http.get<{ [key: string]: number }>(`${resourceUrl}/statistique/download/count-by-type`);
  }

  countDownloadsGroupedByMonth(): Observable<{ [key: string]: number }> {
    return this.http.get<{ [key: string]: number }>(`${resourceUrl}/statistique/download/count-by-month`);
  }
}
