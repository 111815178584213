import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LazyLoadEvent, MenuItem, Message } from 'primeng/api';
import { Document } from 'src/app/models/parametrage/document';
import { environment } from 'src/environments/environment';
import { TypeDocument } from './../../../../models/parametrage/type-document';
import { TypeDocumentService } from './../../../../services/parametrage/type-document.service';
import { SecteursService } from './../../../../services/parametrage/secteurs.service';
import { getAllSecteursResponse } from 'src/app/models/parametrage/secteurs';
import { Secteurs } from './../../../../models/parametrage/secteurs';
import { Search } from 'src/app/models/parametrage/search';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentService } from 'src/app/services/parametrage/document.service';

@Component({
  selector: 'app-textes-autres',
  templateUrl: './textes-autres.component.html',
  styleUrls: ['./textes-autres.component.scss']
})
export class TextesAutresComponent implements OnInit {
  showDialog: boolean = false;
  isOpInProgress: boolean = false;
  enableBtnDownload = true;
  @ViewChild('dtf') form!: NgForm;
  typeselect: TypeDocument = {};
  anneSelect: any;
  numeroSelect: any;
  secteurSelect: Secteurs = {};
  search: Search = {};
  cols: any[] = [];
  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  documents!: Document[];
  document: Document = {};
  typeDocuments!: TypeDocument[];
  secteurs!: Secteurs[];
  documentDetail: boolean = false;
  message: any;
  dialogErrorMessage: any;
  file: Blob | string = '';
  public items: MenuItem[]=[];
  lesTypes: any[]=[];
  lesDocuments: any[]=[];
  searchType:any;
  constructor( private documentService: DocumentService,
    private typeDocumentService: TypeDocumentService,
    private secteursService: SecteursService) { }

  ngOnInit(): void {
    this.load();
    this.getTDFInternationaux();
    this.chargerSecteurs();
    this.items = [
      {label:'LegiBurkina', routerLink:['/accueil'],},
      {label:'Autres',routerLink:['/accueil/textesAutres'],},

  ];
  }


  
  onDownload(fileName: string) {
    console.log(fileName);
    let fileUrl;
    this.documentService.download(fileName).subscribe(blob => {
      fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl);
      //  fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      console.error("url", fileUrl);
      this.showMessage({ severity: 'success', summary: 'document téléchargé avec succès' });
    });

    // this.documentService.download(fileName).subscribe(blob => saveAs(blob, fileName));
    //   console.log("======",documentUrl+'download/'+this.fiches.fileName)
    // window.open(documentUrl+'download/'+this.fiches.fileName,  '_blank');
    //  this.pdfload=true

  }

  //  afficher tous les documents
  getAllDocument() {
    this.documentService.getAll().subscribe(document => this.documents = this.documents);

    this.cols = [

      { field: 'libelle', header: 'DOCUMENTS' },
    ];
  }

  load(event?: LazyLoadEvent) {

    this.documentService.getAll().subscribe(response => {
      this.totalRecords = 0;
      this.documents = response.documents;
      this.documents.forEach(t => {
        let type: any= t.type;
        if(type.libelle.includes("AUTRE")){

          this.lesDocuments.push(t);
          this.totalRecords++;
        }
      });

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }


  // rechercher les documents

  // searchDocument(event?: LazyLoadEvent) {
  //   this.search.type="AUTRE";
  //   this.isOpInProgress = true;
  //   console.log('search',this.search);
  //   this.documentService.filterAutresDocument(this.search).subscribe(response => {
  //     this.documents = response.documents;
  //     this.isOpInProgress = false;

  //   }, error => {
  //     this.message = { severity: 'error', summary: error.error };
  //     console.error(JSON.stringify(error));
  //   });
  // }

   // rechercher les documents

   searchDocument(event?: LazyLoadEvent) {
    this.lesDocuments=[];
    const data={
      type: this.search.type?this.search.type:"",
      anne: this.search.anne ? this.search.anne: 0,
      numero:this.search.numero ? this.search.numero:"",
      secteur:this.search.secteur ? this.search.secteur: ""
    }
    this.isOpInProgress = true;
    console.log(data);
    this.documentService.filterDocument(data).subscribe(response => {
      this.lesDocuments = response;
      console.log("documents",this.lesDocuments);
      this.isOpInProgress = false;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
  // type de textes fondamentaux

  getTDFInternationaux(event?: LazyLoadEvent) {

    this.typeDocumentService.getAllTDInternationaux().subscribe(response => {
      this.typeDocuments = response.typeDocuments;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
  // afficher la liste des secteurs

  chargerSecteurs(event?: LazyLoadEvent) {
    this.secteursService.getAllSecteurs().subscribe(response => {
      this.secteurs = response.listeSecteurs;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  //Détail
  onInfo(selection: any) {
    this.document = Object.assign({}, selection);
    console.log("doc", this.document)
    this.clearDialogMessages();
    this.documentDetail = true;

  }
  // Errors

  handleError(error: HttpErrorResponse) {
    console.error(`Processing Error: ${JSON.stringify(error)}`);
    this.isOpInProgress = false;
    this.dialogErrorMessage = error.error.title;
  }
  // Messages

  clearDialogMessages() {
    this.dialogErrorMessage = null;
  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }

}
