<!-- <span class="created-by">
  Copyright © 2022 Tous droits reservés au <b><a href="https://www.sggcm.gov.bf" ><span>SGG-CM</span></a></b>
  </span> -->
<div class="copyright text-center" style="     width: 100%;
display: flex;
justify-content: center;
align-content: center;
flex-wrap: wrap;">

  &copy; {{ currentYear }} <b><a style="color: #089e4a;margin-left:10px" href="https://www.sggcm.gov.bf"><span>
        SGG-CM</span></a></b>.
  Tous droits réservés.
</div>
<!--<div class="socials">
    <a href="https://www.facebook.com/sggcm" target="_blank" class="ion ion-social-facebook"></a>
    <a href="https://www.youtube.com/@sggcm" target="_blank" class="ion ion-social-youtube"></a>
  </div>-->