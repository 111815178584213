<div>
    <p-card>
        <!-- ToolBar -->
        <div class="p-d-flex">
            <!-- <app-crud-toolbar *ngIf="this.authService.checkPermission(this.permissions, ['ROLE_ADMIN'])" [enableCreate]="enableCreate" (create)="onCreate()">
            </app-crud-toolbar> -->
             <app-crud-toolbar [enableCreate]="enableCreate" (create)="onCreate()">
            </app-crud-toolbar> 
            <div class="p-ml-auto">
                <p-message *ngIf="message" [severity]="message.severity" [text]="message.summary"></p-message>
                <p-progressSpinner *ngIf="isOpInProgress" strokeWidth="4" [style]="{width: '50px', height: '50px'}">
                </p-progressSpinner>
            </div>
        </div>
        <p-divider></p-divider>

        <p-table #dt [value]="profils" [paginator]="true" [rows]="recordsPerPage" [loading]="isLoading" (onLazyLoad)="load($event)" [totalRecords]="totalRecords" [globalFilterFields]="['name','description']" currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
            [showCurrentPageReport]=true [rowsPerPageOptions]="[10, 20]" styleClass="p-datatable-sm">

            <!-- <p-contextMenu #cm [model]="contextMenuItems"></p-contextMenu>
<p-table #dt [value]="agents" [paginator]="true" [rows]="recordsPerPage" [loading]="isLoading" (onLazyLoad)="load($event)" [totalRecords]="totalRecords" [globalFilterFields]="['matricule','nom','prenom','email']" currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
    [showCurrentPageReport]=true [rowsPerPageOptions]="[10, 20]" [(selection)]="selection" selectionMode="single" styleClass="p-datatable-sm" [(contextMenuSelection)]="selection" [contextMenu]="cm"> -->
            <ng-template pTemplate="caption">
                <div class="row">
                    <div class="col" style="padding: 8px; margin-left: 8px">
                        <span>Liste des Profils</span>
                    </div>
                    <div class="col text-right">
                        <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($any($event.target)!.value, 'contains')"
                placeholder="Recherche..." />
            </span>
                    </div>
                </div>

            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">Libéllé
                        <p-sortIcon field="name"></p-sortIcon>

                        <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-profil>
                <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="profil">
                    <td>{{profil.name}} </td>

                    <td>

                        <app-actions-toolbal-iud (info)="onInfo(profil)" [enableBtnInfo]="enableBtnInfo" (edit)="onEdit(profil)" [enableBtnEdit]="enableBtnEdit" (delete)="onDelete(profil)" [enableBtnDelete]="enableBtnDelete" (privilege)="onPrivilege(profil.name)" [enableBtnPrivilege]="enableBtnPrivilege">
                        </app-actions-toolbal-iud>


                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>

    <!-- Dialog-->
    <p-dialog [(visible)]="showDialog" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <i class="{{isEditing() ? 'pi-pencil' : 'pi-plus'}} pi p-mr-1"></i>
            <span class="mr-auto">
      {{profil.id ? 'Modifier' : 'Ajouter'}} un profil
  </span>
        </ng-template>
        <p-progressBar *ngIf="isDialogOpInProgress" mode="indeterminate"></p-progressBar>
        <p-message *ngIf="dialogErrorMessage" severity="error" [text]="dialogErrorMessage"></p-message>
        <p-divider></p-divider>
        <!-- Form -->
        <form (ngSubmit)="save()" #dtf="ngForm">


            <!-- name -->
            <div class="p-fluid grid">
                <div class="p-fluid p-col-12 col-md-12">
                    <label for="name">Nom</label>
                    <input id="name" name="name" #name="ngModel" [(ngModel)]="profil.name" pInputText required />

                </div>
            </div>

            <!-- diviseur -->
            <p-divider></p-divider>
            <div class="p-ml-auto text-right">
                <button type="reset" label="Annuler" (click)="showDialog=false" class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
                <button type="submit" [disabled]="!dtf.form.valid" label="{{isEditing() ? 'Enregistrer' : 'Ajouter'}}" icon="pi {{isEditing() ? 'pi-save' : 'pi-plus'}}" class="p-button-raised p-button-text {{isEditing() ? 'p-button-primary' : 'p-button-success'}}" pButton>
                      </button>
            </div>
        </form>
    </p-dialog>
    <!-- Confirmation -->
    <app-confirmation></app-confirmation>
</div>

<!-- Privilège Dialog-->
<div>
    <p-dialog [(visible)]="showDialogPrivilege" [maximizable]="true" [modal]="true" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <!-- <i class="{{isEditing() ? 'pi-pencil' : 'pi-plus'}} pi p-mr-1"></i> -->
            <span class="p-mr-auto">
<!-- {{profil.id ? 'Modifier' : 'Ajouter'}} un privilege -->
Privilèges
</span>
        </ng-template>
        <p-progressBar *ngIf="isDialogOpInProgress" mode="indeterminate"></p-progressBar>
        <p-message *ngIf="dialogErrorMessage" severity="error" [text]="dialogErrorMessage"></p-message>

        <!-- Content -->
        <div class="p-fluid grid">
            <div class="p-fluid p-col-12 col-md-6">
                <label for="name">Profil:
                  <p-tag styleClass="p-mr-2" severity="success" value="{{ profil.name}}"></p-tag>
                </label>

            </div>
        </div>
        <p-card>
            <h6> Privilèges </h6>

            <div class="row">
                <div id="check" *ngFor="let privilege of privileges" class="p-field-checkbox  col-12 col-md-3">

                    <p-checkbox name="group2" value="privilege" [value]="privilege" [(ngModel)]="profil.privileges" class="m-auto"></p-checkbox>
                    <label [for]="privilege.id">{{privilege.name}}</label>

                </div>
            </div>

            <p-divider></p-divider>
      <div class="p-ml-auto text-right">
          <button type="reset" label="Annuler" (click)="showDialogPrivilege=false" class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
          <button    type="submit" (click)="privilege()" label="Enregistrer" icon="pi pi-save" class="p-button-raised p-button-text p-button-primary" pButton>
          </button>
      </div>


        </p-card>

        <p-divider></p-divider>
    </p-dialog>
    <!-- Confirmation -->
    <app-confirmation></app-confirmation>
</div>
