<div class="header-container">
  <div class="logo-container" style="background-color: #089e48; width: 16rem; border-bottom: 1px solid rgba(227, 231, 241, 0.8);    padding-top: 0px;
    margin-top: 0px;    display: flex;
    justify-content: center;">


    <img class="text-center" src="assets/images/armoirie.png" routerLink="/workspace" alt="Image" width="65" />

  </div>

  <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
    <nb-icon icon="menu-2-outline"></nb-icon>
  </a>


  <!-- Dialog-->


  <!-- partie details profil -->

  <p-dialog [(visible)]="showProfil" [modal]="true" [maximizable]="true"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">

    <ng-template pTemplate="header">

      <span class="p-mr-auto"> DETAILS UTILISATEUR</span>
    </ng-template>
    <div class="row">
      <div class="col-md-12">
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Matricule:</span> {{compte.matricule}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Nom:</span> {{compte.nom}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold"> Prénom:</span> {{compte.prenom}}</span>
          </li>

        </ul>
      </div>
      <div class="col-md-12">
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Statut:</span> {{compte.structureId}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Email:</span> {{compte.email}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold"> Téléphone:</span> {{compte.telephone}} </span>
          </li>

        </ul>
      </div>

    </div>
    <p-divider></p-divider>
    <div class="p-ml-auto text-right">
      <button description="reset" label="Fermer" (click)="showProfil=false"
        class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
    </div>

  </p-dialog>
</div>



<div class="header-container">
  <nb-actions size="medium">
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline" badgeText="+" badgeStatus="danger" (click)="onInfo()">
    </nb-action> -->

    <nb-action class="user-action">
      <!--<nb-action class="user-action" *nbIsGranted="['view', 'user']" -->
      <!--<img src="assets/images/user-icon.svg" routerLink="/workspace" alt="Image" height="40" /> -->

      <nb-user [nbContextMenu]="userMenu" [name]='username'>

      </nb-user>
    </nb-action>

  </nb-actions>
</div>