import { NbMenuItem } from '@nebular/theme';
import { AuthenticationService } from 'src/app/services/parametrage/authentication.service';
let perms = AuthenticationService.getPrivilegeTest();
if(perms == null){
  perms = ['ROLE_GESTIONNAIRE','ROLE_USER'];
}
export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Accueil',
    icon: 'home-outline',
    link: '/accueil',
    home: true,
  },
  {
    title: 'Tableau de bord',
    icon: 'pie-chart-outline',
    link: '/workspace/stat',
    home: false,
   // hidden:!AuthenticationService.checkPermissionTest(perms!,['ROLE_ADMIN','ROLE_DIRCAB_MIN','ROLE_SG_MIN','ROLE_DIR_DGESS','ROLE_RESP_MIN','ROLE_RESP_DGESS']),

  },
  {
    title: 'Documents',
    link: '/workspace/document',
    icon: 'file',
  },
   {
    title: 'Mes Parametres',
    icon: 'settings-outline',
    hidden:!AuthenticationService.checkPermissionTest(perms!,['ROLE_ADMIN']),
 //   hidden:!AuthenticationService.checkPermissionTest(perms!,['ROLE_ADMIN','ROLE_RESP_DGESS','ROLE_DIR_DGESS','ROLE_DAF','ROLE_RESP_DDII']),
    children: [
      // {
      //   title: 'Exemple',
      //   link: '/workspace/exemple',
      //   icon: 'list-outline'

      // },
      {
        title: 'Type document',
        link: '/workspace/typeDocument',
        icon: 'list-outline'
      },
      {
        title: 'Site externe',
        link: '/workspace/siteExterne',
        icon: 'list-outline'
      },
      // {
      //   title: 'Secteurs',
      //   link: '/workspace/secteurs',
      //   icon: 'list-outline'
      // },
      {
        title: 'Références JO',
        link: '/workspace/journalOfficiel',
        icon: 'list-outline'

      },

      {
        title: 'Services',
        link: '/workspace/structure',
        icon: 'list-outline'

      },
      {
        title: 'Publicite',
        link: '/workspace/publicite',
        icon: 'list-outline'
      },

    ],


  },


  {
    title: 'Utilisateurs',
    icon: 'lock-outline',
    hidden:!AuthenticationService.checkPermissionTest(perms!,['ROLE_ADMIN']),
    children: [

      {
        title: 'Compte',
        link: '/workspace/compte',
        icon: 'person-add-outline',
      //  hidden:!AuthenticationService.checkPermissionTest(perms!,['ROLE_ADMIN','ROLE_RESP_STRUCT','ROLE_RESP_DGESS','ROLE_DIR_DGESS'])
      },
      {
        title: 'Agents',
        link: '/workspace/agent',
        icon: 'people-outline'

      },
      {
        title: 'Profils',
        link: '/workspace/profil',
        icon: 'list-outline',
      //  hidden:!AuthenticationService.checkPermissionTest(perms!,['ROLE_ADMIN'])
      },
      {
        title: 'Privilèges',
        link: '/workspace/privilege',
        icon: 'list-outline',
      //  hidden:!AuthenticationService.checkPermissionTest(perms!,['ROLE_ADMIN'])
      },
    ],
  }
];
