<div
  class="shadow-sm cesw-section cesw-section-img-tree cesw-section-img-contact card contact-banner"
>
  <div class="container">
    <div class="page-header">
      <div>
        <h1 class="page-title">Nous contacter</h1>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/"><i class="zmdi zmdi-home zmdi-hc-lg"></i></a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <i
              style="margin-top: -10px; font-size: 20px; margin-right: 5px"
              aria-hidden="true"
              class="fa fa-home"
            ></i>
            <p-breadcrumb [model]="items"></p-breadcrumb>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div
    class="row d-flex items-center justify-content-center"
    style="margin-top: 50px; margin-bottom: 50px"
  >
    <div class="col-8">
      <div class="row grap-1">
        <div class="col pr-0 pl-0 border-right">
          <div class="card-body contact-bg-section text-center">
            <h6 class="mb-0">
              <div><i class="fa fa-phone"></i></div>
              Numéro de téléphone
            </h6>
            <h2 class="mb-1 mt-2 number-font">+226 25 40 99 72</h2>
            <h2 class="mb-1 mt-2 number-font">+226 25 40 99 74</h2>
          </div>
        </div>
        <div class="col pr-0 pl-0 border-right">
          <div class="card-body contact-bg-section text-center">
            <h6 class="mb-0">
              <div><i class="fa fa-envelope"></i></div>
              E-mail
            </h6>
            <h2 class="mb-1 mt-2 number-font">legiburkina@sggcm.gov.bf</h2>
          </div>
        </div>
        <div class="col pr-0 pl-0">
          <div class="card-body contact-bg-section text-center">
            <h6 class="mb-0">
              <div><i class="fa fa-map-marker"></i></div>
              Adresse
            </h6>
            <h2 class="mb-1 mt-2 number-font">
              9FFR+2R7, Koulouba, Ouagadougou
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3897.1549606538983!2d-1.5105386241791467!3d12.372550427727278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe2ebe3bbc44b86d%3A0x5f2454f819fb6c71!2sSecr%C3%A9tariat%20G%C3%A9n%C3%A9ral%20Du%20Gouvernement!5e0!3m2!1sen!2sbf!4v1721823902046!5m2!1sen!2sbf"
    width="100%"
    height="450"
    style="border: 0"
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe
  ><br /><br /><br />
</div>
