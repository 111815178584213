import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Search2 } from 'src/app/models/parametrage/search';
import { TypeStateVo } from 'src/app/models/vo/document.type.state';
import { environment } from 'src/environments/environment';

const dhsDocumentRessourceUrl = environment.dhsDocumentRessource;
const dhsStatDocumentUrl = environment.dhsStatDocument;
const userRessourceUrl= environment.userRessource;


@Injectable({
  providedIn: 'root'
})
export class StatDashoardService {

  constructor(private http: HttpClient) { }

  getDocumentBundle(annee?:number): Observable<any> {
    return this.http.get(`${dhsDocumentRessourceUrl}/${annee}` , { observe: 'response' })
    .pipe(map(response => {
        let globalResponse: any = response.body as any ;
        return globalResponse;
      }));
  }



  getDocumentDownload(documentCriteria: Search2): Observable<TypeStateVo[]> {
    return this.http.post<TypeStateVo[]>(`${dhsStatDocumentUrl}` , documentCriteria)
    .pipe(map((response: any) => {
      console.log('DOWNLOAD STATE ', response);
        let globalResponse: any = response as TypeStateVo[] ;
        return globalResponse;
      }));
  }
  getNbreUtilisateur(): Observable<any> {
    return this.http.get(userRessourceUrl, { observe: 'response' })
    .pipe(map(response => {
        let globalResponse: any = response.body as any ;
        return globalResponse;
      }));
  }
}
