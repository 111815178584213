import {HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetAllTDocumentResponse, TypeDocument } from 'src/app/models/parametrage/type-document';
import { environment } from 'src/environments/environment';
const resourceUrl = environment.typeDocumentResource;
@Injectable({
  providedIn: 'root'
})
export class TypeDocumentService {

  constructor(
    private http: HttpClient
  ) { }

  getAllTDocument(): Observable<GetAllTDocumentResponse> {
    return this.http.get(resourceUrl, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let typeDocumentsResponse: GetAllTDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          typeDocuments: response.body as TypeDocument[]
        };
        return typeDocumentsResponse;
      }));
  }

  getAllTDFondamentaux(): Observable<GetAllTDocumentResponse> {
    return this.http.get(`assets/data/typeDocumentFond.json`, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let typeDocumentsResponse: GetAllTDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          typeDocuments: response.body as TypeDocument[]
        };
        return typeDocumentsResponse;
      }));
  }
  getAllTDLegislatifs(): Observable<GetAllTDocumentResponse> {
    return this.http.get(`assets/data/typeDocumentLegi.json`, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let typeDocumentsResponse: GetAllTDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          typeDocuments: response.body as TypeDocument[]
        };
        return typeDocumentsResponse;
      }));
  }
  getAllTDReglementaires(): Observable<GetAllTDocumentResponse> {
    return this.http.get(`assets/data/typeDocumentReg.json`, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let typeDocumentsResponse: GetAllTDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          typeDocuments: response.body as TypeDocument[]
        };
        return typeDocumentsResponse;
      }));
  }



  getAllTDInternationaux(): Observable<GetAllTDocumentResponse> {
    return this.http.get(`assets/data/typeDocumentInter.json`, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let typeDocumentsResponse: GetAllTDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          typeDocuments: response.body as TypeDocument[]
        };
        return typeDocumentsResponse;
      }));
  }

  getAllTDCommunautaire(): Observable<GetAllTDocumentResponse> {
    return this.http.get(`assets/data/typeDocumentCommu.json`, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let typeDocumentsResponse: GetAllTDocumentResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          typeDocuments: response.body as TypeDocument[]
        };
        return typeDocumentsResponse;
      }));
  }

  create(request: TypeDocument): Observable<TypeDocument> {
    return this.http.post(resourceUrl, request);
  }

  update(typeDocument: TypeDocument): Observable<TypeDocument> {
    return this.http.put(resourceUrl, typeDocument);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${resourceUrl}/${id}`);
  }

  findListe(): Observable<HttpResponse<TypeDocument[]>> {
    return this.http.get<TypeDocument[]>(`${resourceUrl}/list`, { observe: 'response' });
  }

}
