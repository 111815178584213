import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services/parametrage/document.service';
import { Document } from 'src/app/models/parametrage/document';
import { Message } from 'primeng/api';
@Component({
  selector: 'app-charte',
  templateUrl: './charte.component.html',
  styleUrls: ['./charte.component.scss']
})
export class CharteComponent implements OnInit {
  documents!: Document[];
  message:any;
  lesTypes: any[]=[];
  lesDocuments: any[]=[];
  searchType:any;
  timeoutHandle: any;
  constructor(
    private documentService :DocumentService
  ) { }

  ngOnInit(): void {
    this.getAllCharte();
  }
  getAllCharte(){

    // this.documentService.getAllByType("CHARTE").subscribe(response => {

    //   this.documents = response.documents;

    // }, error => {
    //   this.message = { severity: 'error', summary: error.error };
    //   console.error(JSON.stringify(error));
    // });

    this.documentService.getAll().subscribe(response => {

      this.documents = response.documents;
      this.documents.forEach(t => {
        let type: any= t.type;
        if(type.libelle.includes("CHAR")){

          this.lesDocuments.push(t);
        }
      });

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  onDownload(fileName: any) {
    console.error(fileName);
    let fileUrl;
    this.documentService.download(fileName).subscribe(blob => {
      fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl);
      //  fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      console.error("url", fileUrl);
      this.showMessage({ severity: 'success', summary: 'document téléchargé avec succès' });
    });

  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }

}
