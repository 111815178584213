import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, Message } from 'primeng/api';
import { Document } from 'src/app/models/parametrage/document';

import { DocumentService } from 'src/app/services/parametrage/document.service';
import { environment } from 'src/environments/environment';
import { JournalOfficiel } from '../../../../models/parametrage/journal-officiel';
import { Secteurs } from 'src/app/models/parametrage/secteurs';
import { TypeDocument } from 'src/app/models/parametrage/type-document';
import { TypeDocumentService } from '../../../../services/parametrage/type-document.service';
import { SecteursService } from 'src/app/services/parametrage/secteurs.service';
import { JournalOfficielService } from '../../../../services/parametrage/journal-officiel.service';
import { ActionsToolbalIudComponent } from '../../../../common/actions-toolbal-iud/actions-toolbal-iud.component';
import { saveAs } from 'file-saver';
import { AuthenticationService } from './../../../../services/parametrage/authentication.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  @ViewChild('dtf') form!: NgForm;
  type:any;
  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  documents!: Document[];
  document: Document= {};
  types!: TypeDocument[];
  secteurs!: Secteurs[];
  journalOfficiels!:JournalOfficiel[];
  enableCreate = true;
  enableBtnInfo = true;
  enableBtnEdit = true;
  enableBtnDelete=true;
  enableBtnDownload=true;
  isLoading!: boolean;
  isOpInProgress!: boolean;
  isDialogOpInProgress!: boolean;
  showDialog = false;
  documentDetail: boolean=false;
  message: any;
  dialogErrorMessage: any;
  file: Blob | string = '';
  permissions:any;
  champActif:boolean=true;
  dateV: string = "";
  lesType: any[]=[]
  constructor(
    private documentService: DocumentService,
    private confirmationService: ConfirmationService,
    private typeService: TypeDocumentService,
    private secteurService: SecteursService,
    private journalOfficielService: JournalOfficielService,
    private authService : AuthenticationService
  ) { }

  ngOnInit(): void {
    this.load();
    this.getType();
    this.getSecteur();
    this.getJournalOfficiel();
    this.enableBtnDelete=this.authService.checkPermission(this.permissions, ['ROLE_ADMIN']);
  }

  isEditing() {
    return !!this.document.id;
  }
 // Affichage

  load(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.documentService.getAll().subscribe(response => {
      this.isLoading = false;
      this.documents = response.documents;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  getType(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.typeService.getAllTDocument().subscribe(response => {
      this.isLoading = false;
      this.types = response.typeDocuments;
      // this.types.forEach(t => {
      //   if(t.libelle?.startsWith("A")){
      //     let type=t.libelle;
      //     this.lesType.push(type);
      //   }

      //  console.log("pppppp", this.lesType)
      // });

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });

  }

  getSecteur(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.secteurService.getAllSecteurs().subscribe(response => {
      this.isLoading = false;
      this.secteurs = response.listeSecteurs;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  getJournalOfficiel(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.journalOfficielService.getAll().subscribe(response  => {
      this.isLoading = false;
      this.journalOfficiels = response.journalOfficiels;
      console.log("reference journal : ", response);
    }, (error: { error: any; }) => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  onChange(event:any) {
    // console.log('event :' + event);
    // console.log(event.value.libelle);
    let typeDoc = event.value.libelle;
    console.log(typeDoc);
    if(typeDoc.includes("POLI") || typeDoc.includes("STRA") || typeDoc.includes("CM")){
      this.champActif=true;
    }else{this.champActif=true;}
}

  //Creation

  save() {
    if (this.document.id) {
      this.update();
    } else {
      this.create();
    }
  }

  onCreate() {
    this.document = {};
    this.clearDialogMessages();
    this.form.resetForm();
    this.showDialog = true;
  }

  create() {
    this.clearDialogMessages();

    if(this.dateV.length == 10){
      let dateParts = this.dateV.split("/");
      this.dateV = new Date(dateParts[2]+"/"+dateParts[1]+"/"+dateParts[0]).toISOString();
    }

    if(this.dateV.length != 10){
      this.dateV = new Date(this.dateV).toISOString();
    }

    this.document.date = new Date(this.dateV);

    this.isDialogOpInProgress = true;
    const formData: FormData = new FormData();
    const fichesAsJson: Blob = new Blob([JSON.stringify(this.document)], { type: 'application/json' });
    formData.append('documentDTO', fichesAsJson);
    formData.append('documentFile', this.file);

    this.documentService.create(formData).subscribe(response => {
      this.load();
      this.totalRecords++;
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'document enregistré avec succès' });
    }, error => this.handleError(error));

  }


  onDownload(fileName :string){
    console.log(fileName);
    let fileUrl;
    this.documentService.download(fileName).subscribe(blob =>  {
      fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl);
    //  fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
       console.error("url", fileUrl);
       this.showMessage({ severity: 'success', summary: 'document téléchargé avec succès' });
    });
   }

  async voirDocument(filname?: string): Promise<void> {
    console.warn("fi",filname);
    if (filname) {
      const link = await this.documentService.visualiser(
        filname
      );
      if (link) {
        window.open(link, '_blank');
      }
    }
  }





  //Détail
  onInfo(selection: any) {
    this.document = Object.assign({}, selection);
    this.clearDialogMessages();
    this.documentDetail=true;
  }

    // Edit

    onEdit(selection: any) {
      this.dateV = ((selection.date!).toString()).substring(8,10)+"/"+((selection.date!).toString()).substring(5,7)+"/"+((selection.date!).toString()).substring(0,4);
      this.document = Object.assign({}, selection);
      this.clearDialogMessages();
      this.showDialog = true;
    }

  update() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
    console.warn("DOCUMENT A MODIF",this.document);
    this.documentService.update(this.document).subscribe(response => {
      let index = this.documents.findIndex(publicite => publicite.url === response.url);
      this.documents[index] = response;
      this.load();
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'Document modifiée avec succès' });
    }, error => this.handleError(error));
  }

    edit() {
    console.warn("this.document",this.document);
      this.clearDialogMessages();
      this.isDialogOpInProgress = true;

      if(this.dateV.length == 10){
        let dateParts = this.dateV.split("/");
        this.dateV = new Date(dateParts[2]+"/"+dateParts[1]+"/"+dateParts[0]).toISOString();
      }

      if(this.dateV.length != 10){
        this.dateV = new Date(this.dateV).toISOString();
      }


      this.document.date = new Date(this.dateV);

      const formData: FormData = new FormData();
    const fichesAsJson: Blob = new Blob([JSON.stringify(this.document)], { type: 'application/json' });
    formData.append('documentDTO', fichesAsJson);
    formData.append('documentFile', this.file);

    console.log(this.document);
    // console.log(formData.get('documentFile'));

     /* this.documentService.update(formData).subscribe(response => {
        let index = this.documents.findIndex(document => document.id === response.id);
        this.documents[index] = response;
        this.isDialogOpInProgress = false;
        this.showDialog = false;
        this.showMessage({ severity: 'success', summary: 'document modifié avec succès' });
      }, error => this.handleError(error));*/
    }

  onUpload(file: any) {

      if (file) {
        console.warn("info file",file);
        this.documentService.upload(file).subscribe({
          next: (response) => {
            console.warn("response",response.body);
            this.document.url = response.body.url;
            this.document.localUrl = response.body.url;

            console.warn("Document complet to update",this.document);
          },
          error: (error) => {
            console.error("error" + JSON.stringify(error));

            this.showMessage({severity: 'error', summary: error.error.message});
          }
        });
      }
  }


    // Deletion

  onDelete(selection: any) {
    this.confirmationService.confirm({
      message: 'Etes-vous sur de vouloir supprimer cet document?',
      accept: () => {
        this.delete(selection);
      }
    });
  }

  delete(selection: any) {
    this.isOpInProgress = true;
    this.documentService.delete(selection.id).subscribe(() => {
      this.documents = this.documents.filter(document => document.id !== selection.id);
      selection = null;
      this.isOpInProgress = false;
      this.totalRecords--;
      this.showMessage({
        severity: 'success',
        summary: 'Document supprimé avec succès',
      });
    },(error) => this.handleError(error)
  );
  }

  onSelectFile(event:any): void {
    // console.log(event.files[0]);
    // console.log(event.files[0].name);
    let file:File = event.files[0];
    this.file = file;
    if(this.document.id){
      this.onUpload(this.file);
    }

    // this.document.fileName =  event.files[0].name;
    // this.document.fileSize = event.files[0].size;
  }
    // Errors

    handleError(error: HttpErrorResponse) {
      console.error(`Processing Error: ${JSON.stringify(error)}`);
      this.isDialogOpInProgress = false;
      this.dialogErrorMessage = error.error.title;
    }
     // Messages

  clearDialogMessages() {
    this.dialogErrorMessage = null;
  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }



}
