import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {LazyLoadEvent, Message} from 'primeng/api';
import {DocumentService} from 'src/app/services/parametrage/document.service';
import {environment} from 'src/environments/environment';
import {Document} from 'src/app/models/parametrage/document';
import {DocumentSearch, GlobalSearch} from './../../../models/vo/document-search';
import {TypeDocumentService} from "../../../services/parametrage/type-document.service";
import {TypeDocument} from "../../../models/parametrage/type-document";
import {Search} from "../../../models/parametrage/search";
import {PubliciteService} from "../../../services/parametrage/publicite.service";
import {Publicite} from "../../../models/parametrage/publicite";
import {DomSanitizer} from "@angular/platform-browser";
import {PageEvent} from "../../../models/page-event.model";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  accueilHidden: boolean=false;
  showDialog: boolean = false;
  isOpInProgress: boolean = false;
  documentDetail: boolean=false;
  viewDetail: boolean=false;
  document: Document = {};
  message: any;
  dialogErrorMessage: any;
  timeoutHandle: any;
  documents!: Document[];
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  globalSearch: GlobalSearch={};

  //searchCriteriaList: DocumentSearch = {};
  researchHidden: boolean=true;
  actualiteHidden: boolean=false;
  file: Blob | string = '';
  types: TypeDocument[] = [];
  typeDocSelected?:string;
  listeDocuments: Document[] = [];
  listeDocumentsFiltrer: Document[] = [];
  documentSelected: Document = {};
  publicites: Publicite[] = [] ;
  image:any;
  blob?: string;
  responsiveOptions: any[] = [];
  isLoading: boolean = false;
  searchFilter: Search = new Search();
  lesTypes: any[]=[];
  typeDocuments!: TypeDocument[];
  searchType:any;

  totalItems = 0;
  itemsPerPage = 10;
  page = 0;
  first: number = 0;
  rows: number = 5;
  last: number = 0;

  isSearching: boolean = false;


  constructor( private documentService: DocumentService,
               private typeService: TypeDocumentService,
               private publiciteService: PubliciteService,
               private typeDocumentService: TypeDocumentService,
               private sanitizer: DomSanitizer,) {

  }

  ngOnInit(): void {
   this.getDocumentRecent();
   this.getType();
   this.loadAllPublicite();
   this.isLoading = false;
   const search: Search = new Search();
  //  this.typeDocSelected = "ARRETE";
   search.type = this.typeDocSelected;
   this.searchDocument(search);
   this.isSearching = false;
   this.searchFilter.numero = "";
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];

 //  this.getBlob("http://localhost:4200/Users/anptic/image-sggcm/1702565539748_anptic.jpg")
  }
  load() {
    this.documentService.filterByKeyWord(this.searchFilter,this.page,this.itemsPerPage).subscribe(response => {
     // this.documents = response.documents ;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  searchDocumentFilter(event?: LazyLoadEvent) {
    this.listeDocumentsFiltrer = this.listeDocuments.filter((item: Document) => {
      let result = true;
      console.log("Type :", this.searchFilter?.type, item?.type, this.searchFilter?.type?.map((s:any) => s?.libelle), item?.type?.libelle, this.searchFilter?.type?.map((s:any) => s?.libelle)?.includes(item?.type?.libelle));
      console.log("result reference :", this.searchFilter?.numero, typeof this.searchFilter?.numero, this.searchFilter?.numero && this.searchFilter?.numero != "", (item?.reference && item?.reference != "" ? item?.reference?.toLocaleLowerCase()?.includes(this.searchFilter?.numero?.toLocaleLowerCase()!) : true));
      result = this.searchFilter?.anne && this.searchFilter?.anne.toString() != "" ? 
        item?.date?.toString()?.includes(this.searchFilter?.anne?.toString()!) ?? true : true;
      if(item?.reference?.toLocaleLowerCase()?.includes(this.searchFilter?.numero?.toLocaleLowerCase()!) != undefined) result = result && (this.searchFilter?.numero && this.searchFilter?.numero != undefined && this.searchFilter?.numero != "" ? item?.reference?.toLocaleLowerCase()?.includes(this.searchFilter?.numero?.toLocaleLowerCase()!) : true);
      result = result && (this.searchFilter?.type && this.searchFilter?.type.length > 0 ? this.searchFilter?.type?.map((s:any) => s?.libelle)?.includes(item?.type?.libelle) : true);

      return result;
    });
  }

  getTypeDocument(event?: LazyLoadEvent) {
    
    this.typeDocumentService.getAllTDocument().subscribe(response => {
      
      this.typeDocuments = response.typeDocuments;
      this.typeDocuments.forEach(t => {
        if(t.libelle?.includes("CON")||t.libelle?.includes("CHA")){
          this.searchType=t.libelle;
          this.lesTypes.push(this.searchType);
        }

      });

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
    
  }

  loadAllPublicite(){
    this.publiciteService.findListe().subscribe(response => {
      this.publicites = response.body!;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  getDocumentRecent(){
    this.load();
  }


  onDownload(fileName: any) {
    console.error(fileName);
    let fileUrl;
    this.documentService.download(fileName).subscribe(blob => {
      fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl);
      //  fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      console.error("url", fileUrl);
      this.showMessage({ severity: 'success', summary: 'document téléchargé avec succès' });
    });

    // this.documentService.download(fileName).subscribe(blob => saveAs(blob, fileName));
    //   console.log("======",documentUrl+'download/'+this.fiches.fileName)
    // window.open(documentUrl+'download/'+this.fiches.fileName,  '_blank');
    //  this.pdfload=true

  }

  /*globalSearchDocument(event?: LazyLoadEvent) {
    this.actualiteHidden=true;
    this.researchHidden=false;
    this.isOpInProgress = true;
   // this.searchCriteriaList.filterKey = "libelle";
   // this.searchCriteriaList.operation = "cn";
   // this.globalSearch.searchCriteriaList = this.searchCriteriaList;
    console.log("______________",this.globalSearch)
    this.documentService.globalfilterDocument(this.globalSearch).subscribe(response => {
      this.documents = response;
      this.isOpInProgress = false;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }*/
  //Détail
  onInfo(selection: any) {
    this.document = Object.assign({}, selection);
    console.log("doc", this.document)
    this.clearDialogMessages();
    this.documentDetail = true;

  }
  // Errors

  handleError(error: HttpErrorResponse) {
    console.error(`Processing Error: ${JSON.stringify(error)}`);
    this.isOpInProgress = false;
    this.dialogErrorMessage = error.error.title;
  }
  // Messages

  clearDialogMessages() {
    this.dialogErrorMessage = null;
  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }

  getType() {
    this.typeService.findListe().subscribe(response => {
      this.types = response.body!;
      console.warn("type document",this.types);
    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });

  }
  test(libelle: string){
    //this.typeDocSelected = libelle;
    this.searchFilter.type = libelle;
    this.searchDocument(this.searchFilter)

  }

  shortenFileName(fileName: string, maxLength: number): string {
    if (fileName.length <= maxLength) {
      return fileName;
    }
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
    const visiblePart = maxLength - fileExtension.length;
    return fileName.substring(0, visiblePart) + '...' + fileExtension;
  }

  searchDocument(search: Search) {
    this.isSearching = true;
    console.log("searchDocument");
    this.isOpInProgress = true;
    this.documentService.filterByKeyWord(search,this.page,this.itemsPerPage).subscribe(response => {
      console.warn("RESP HEADER",response.headers);
      this.listeDocuments = response.body;
      this.listeDocumentsFiltrer = response.body;
      this.totalItems = Number(response.headers.get('X-Total-Count'));
      //tpsm
      //this.totalItems = 2300;
      //
      console.warn("totalItems",this.totalItems);
      this.isOpInProgress = false;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.warn(JSON.stringify(error));
    });
  }


  getDetailDoc(selection: any) {
    console.warn("seleted",selection);
    this.documentSelected = Object.assign({}, selection);
    this.clearDialogMessages();
    this.documentDetail=true;
  }

  fermer() {
    this.documentDetail = false;
  }

  getSize(doc: Document): any {
    const fileSizeBytes = doc.fileSize!; // bytes
    return (Math.round(fileSizeBytes / (1024 ** 2) * 100) / 100).toFixed(2);
  }


  onPageChange(event: PageEvent) {
    this.first = event.first;
    this.page = event.page;
    this.itemsPerPage = event.rows;
    this.rows = event.rows;
    this.last = this.first + this.itemsPerPage
    this.searchDocument(new Search());
  }

}
