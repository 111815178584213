import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeRoutingModule } from './theme-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NbAccordionModule, NbActionsModule, NbButtonModule, NbContextMenuModule, NbDialogModule, NbIconModule, NbLayoutModule, NbMenuModule, NbSearchModule, NbSelectModule, NbSidebarModule, NbUserModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { MenuComponent } from './components/menu/menu.component';
import { ThemeLandingComponent } from './components/theme-landing/theme-landing.component';
import { NbSecurityModule } from '@nebular/security';
import { OneColumnLayoutComponent } from './components/layouts/one-column-layout/one-column-layout.component';
import { TwoColumnLayoutComponent } from './components/layouts/two-column-layout/two-column-layout.component';
import { ThreeColumnLayoutComponent } from './components/layouts/three-column-layout/three-column-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuParametreComponent } from './components/menu-parametre/menu-parametre.component';
import {MenubarModule} from 'primeng/menubar';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import {BadgeModule} from 'primeng/badge';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SendMailComponent } from './components/send-mail/send-mail.component';
import {PasswordModule} from 'primeng/password';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { MenuPublicComponent } from './components/menu-public/menu-public.component';
import { FooterPublicComponent } from './components/footer-public/footer-public.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { TextesReglementairesComponent } from '../components/public/pages/textes-reglementaires/textes-reglementaires.component';
import { PanelModule } from 'primeng/panel';
import {AccordionModule} from 'primeng/accordion';
import { ConstitutionComponent } from './components/constitution/constitution.component';
import { CharteComponent } from './components/charte/charte.component';
import {CarouselModule} from "primeng/carousel";
import {TagModule} from "primeng/tag";
import {RippleModule} from "primeng/ripple";
import {PaginatorModule} from "primeng/paginator";
import { NgbCarouselModule, NgbModule, NgbSlide, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    ThemeLandingComponent,
    OneColumnLayoutComponent,
    TwoColumnLayoutComponent,
    ThreeColumnLayoutComponent,
    MenuParametreComponent,
    LoginComponent,
    PageNotFoundComponent,
    SendMailComponent,
    LandingPageComponent,
    MenuPublicComponent,
    FooterPublicComponent,
    HomePageComponent,
    ConstitutionComponent,
    CharteComponent,


  ],
    imports: [
        PanelModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        NbUserModule,
        DialogModule,
        ButtonModule,
        DividerModule,
        TableModule,
        PasswordModule,
        CardModule,
        AccordionModule,
        BadgeModule,
        ReactiveFormsModule,
        CommonModule,
        NbActionsModule,
        NbIconModule,
        NbEvaIconsModule,
        NbSearchModule,
        NbSidebarModule,
        NbContextMenuModule,
        NbSecurityModule,
        NbButtonModule,
        NbSelectModule,
        NbLayoutModule,
        NbMenuModule,
        NbIconModule,
        NbEvaIconsModule,
        NbDialogModule.forChild(),
        ThemeRoutingModule,
        MenubarModule,
        CarouselModule,
        TagModule,
        RippleModule,
        PaginatorModule,
        NgbCarouselModule,

        NgbTooltipModule,
        NgbModule,

        ProgressSpinnerModule,
        MessageModule,
        MultiSelectModule

    ],
  providers: [],
})

export class ThemeModule { }
