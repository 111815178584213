import { JournalOfficielService } from 'src/app/services/parametrage/journal-officiel.service';
import { JournalOfficiel, NatureJournal } from './../../../../models/parametrage/journal-officiel';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService } from './../../../../services/parametrage/authentication.service';



@Component({
  selector: 'app-journal-officiel',
  templateUrl: './journal-officiel.component.html',
  styleUrls: ['./journal-officiel.component.scss']
})
export class JournalOfficielComponent implements OnInit {

  @ViewChild('dtf') form!: NgForm;



  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  journalOfficiels!: JournalOfficiel[];
  journalOfficiel: JournalOfficiel= {};
  natureJournals!: NatureJournal[];
  enableCreate = true;
  enableBtnInfo = true;
  enableBtnEdit = true;
  enableBtnDelete=true;
  isLoading!: boolean;
  isOpInProgress!: boolean;
  isDialogOpInProgress!: boolean;
  showDialog = false;
  exempleDetail: boolean=false;
  message: any;
  journalOfficielDetail: boolean=false;
  dialogErrorMessage: any;
  permissions: any;
  
  constructor(
    private  journalOfficielService: JournalOfficielService,
    private confirmationService: ConfirmationService,
    private authService: AuthenticationService
  ) 
  
  { }

  ngOnInit(): void {
    this.permissions=this.authService.getPrivilege();
    this.enableBtnDelete=this.authService.checkPermission(this.permissions, ['ROLE_ADMIN']);
    this.chargerJournal();
    this.chargerNatureJournals();
  }

  isEditing() {
    return !!this.journalOfficiel.id;
  }
 // Affichage

 chargerJournal(event?: LazyLoadEvent) {
  this.isLoading = true;
  this.journalOfficielService.getAll().subscribe(response  => {
    this.isLoading = false;
    this.journalOfficiels = response.journalOfficiels;

  }, (error: { error: any; }) => {
    this.message = { severity: 'error', summary: error.error };
    console.error(JSON.stringify(error));
  });
}

chargerNatureJournals(event?: LazyLoadEvent) {
  this.isLoading = true;
  this.journalOfficielService.getNatureJournal().subscribe(response  => {
    this.isLoading = false;
    this.natureJournals = response.natureJournals;

  }, (error: { error: any; }) => {
    this.message = { severity: 'error', summary: error.error };
    console.error(JSON.stringify(error));
  });
}

save() {
  if (this.journalOfficiel.id) {
    this.edit();
  } else {
    this.create();
  }
}

onCreate() {
  this.journalOfficiel = {};
  this.clearDialogMessages();
  this.form.resetForm();
  this.showDialog = true;
}

create() {
  this.clearDialogMessages();
  this.isDialogOpInProgress = true;
  this.journalOfficielService.create(this.journalOfficiel).subscribe(response => {
   // console.error("quelque chose", this.journalOfficiel);
    if (this.journalOfficiels.length !== this.recordsPerPage) {
      this.journalOfficiels.push(response);
      this.journalOfficiels = this.journalOfficiels.slice();    
    }
    this.totalRecords++;
    this.isDialogOpInProgress = false;
    this.showDialog = false;
    this.showMessage({ severity: 'success', summary: 'Le journal  créé avec succès' });
  }, (error: HttpErrorResponse) => this.handleError(error));
}

//Détail
onInfo(selection: any) {
  this.journalOfficiel = Object.assign({}, selection);
  this.clearDialogMessages();
  this.journalOfficielDetail=true;
}

  // Edit

  onEdit(selection: any) {
    this.journalOfficiel = Object.assign({}, selection);
    this.clearDialogMessages();
    this.showDialog = true;
  }

  edit() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
    this.journalOfficielService.update(this.journalOfficiel).subscribe(response => {
      let index = this.journalOfficiels.findIndex(journalOfficiel => journalOfficiel.id === response.id);
      this.journalOfficiels[index] = response;
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'journal modifié avec succès' });
    }, (error: any) => this.handleError(error));
  }
  //clearDialogMessages() {
   // throw new Error('Method not implemented.');
  //}
  handleError(error: any) {
    throw new Error('Method not implemented.');
  }
  showMessage(...args: [arg0: { severity: string; summary: string; }]) {
    this.message = this.message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }
  onDelete(selection: any) {
    this.confirmationService.confirm({
      message: 'Etes-vous sur de vouloir supprimer ce journal?',
      accept: () => {
        this.delete(selection);
      }
    });

}

clearDialogMessages() {
  this.dialogErrorMessage = null;
}
  delete(selection: any) {
    throw new Error('Method not implemented.');
  }
}

