<div class="container" id="resset-password" style="">
	<p-card style="background-color: #ffffff;" class="custom-form-style">
		<form (ngSubmit)="onUpdate()" #dtf="ngForm">
			<p style="font-weight: bold;">Modifier mon mot de passe</p>
			<p-divider></p-divider>
			<div class="p-fluid p-grid">
				<div class="p-fluid p-col-12 p-md-4">
					<label for="password">Ancien mot de passe</label>
					<p-password id="password" [toggleMask]="true" name="password" #password="ngModel"
						[(ngModel)]="changePassword.currentPassword" required></p-password>
				</div>
				<div class="p-fluid p-col-12 p-md-4">
					<label for="password">Nouveau mot de passe</label>
					<p-password id="password" [toggleMask]="true" name="password" #password="ngModel"
						[(ngModel)]="changePassword.newPassword" required></p-password>
				</div>
			</div>
			<p-divider></p-divider>
			<div class="p-ml-auto text-right">
				<button type="submit" [disabled]="!dtf.form.valid" label="Enregistrer" icon="pi 'pi-save'"
					class="p-button-raised p-button-text p-button-success" pButton>
				</button>
			</div>
		</form>
	</p-card>
</div>