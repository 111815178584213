import { Secteurs } from './secteurs';
import { TypeDocument } from './type-document';
export interface Search {
    type?:any |null;
    anne?: number|null;
    numero?: string|null;
    secteur?:string|null
    keyWord?:string|null
}

export class Search implements Search{
  constructor(
    public type?: any|null,
    public anne?: number|null,
    public numero?: string|null,
    public secteur?: string|null,
    public keyWord?:string|null
  ){}
}

export interface Search2 {
  numero?: string | null;
  jour?: number | null;
  mois?: number | null;
  anne?: number | null;
  type?: string|null,
  //date?:Date
}

export class Search2 implements Search2{
  constructor(
    public numero?: string|null,
    public type?: string|null,
    public jour?: number|null,
    public mois?: number|null,
    public anne?: number|null,
  ){}
}
