import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LazyLoadEvent, MenuItem, Message } from 'primeng/api';
import { Document } from 'src/app/models/parametrage/document';
import { environment } from 'src/environments/environment';
import { TypeDocument } from './../../../../models/parametrage/type-document';
import { TypeDocumentService } from './../../../../services/parametrage/type-document.service';
import { SecteursService } from './../../../../services/parametrage/secteurs.service';
import { Secteurs } from './../../../../models/parametrage/secteurs';
import { Search, Search2 } from 'src/app/models/parametrage/search';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentService } from 'src/app/services/parametrage/document.service';
@Component({
  selector: 'app-conseils-ministre',
  templateUrl: './conseils-ministre.component.html',
  styleUrls: ['./conseils-ministre.component.scss']
})
export class ConseilsMinistreComponent implements OnInit {


  showDialog: boolean = false;
  isOpInProgress: boolean = false;
  enableBtnDownload = true;
  @ViewChild('dtf') form!: NgForm;
  typeselect: TypeDocument = {};
  anneSelect: any;
  numeroSelect: any;
  secteurSelect: Secteurs = {};
  //search: Search = {};
  search: Search2 = {};
  cols: any[] = [];
  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  documents!: Document[];
  document: Document = {};
  typeDocuments!: TypeDocument[];
  secteurs!: Secteurs[];
  documentDetail: boolean = false;
  message: any;
  dialogErrorMessage: any;
  file: Blob | string = '';
  public items: MenuItem[]=[];
  lesTypes: any[]=[];
  lesDocuments: any[]=[];
  searchType:any;
  constructor(
    private documentService: DocumentService,
    private typeDocumentService: TypeDocumentService,
    private secteursService: SecteursService
  ) {

  }

  ngOnInit(): void {
    this.load();
    this.getTDCommunautaire();
    this.chargerSecteurs();
    this.items = [
      {label:'LegiBurkina', routerLink:['/accueil'],},
      {label:'RapportCM',routerLink:['/accueil/conseilsMinistres'],},

  ];
  }


  onDownload(fileName: string) {
    console.log(fileName);
    let fileUrl;
    this.documentService.download(fileName).subscribe(blob => {
      fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl);
      //  fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      console.error("url", fileUrl);
      this.showMessage({ severity: 'success', summary: 'document téléchargé avec succès' });
    });

    // this.documentService.download(fileName).subscribe(blob => saveAs(blob, fileName));
    //   console.log("======",documentUrl+'download/'+this.fiches.fileName)
    // window.open(documentUrl+'download/'+this.fiches.fileName,  '_blank');
    //  this.pdfload=true

  }

    //  afficher tous les documents
    getAllDocument() {
      this.documentService.getAll().subscribe(document => this.documents = this.documents);

      this.cols = [

        { field: 'libelle', header: 'DOCUMENTS' },
      ];
    }

    load(event?: LazyLoadEvent) {

      this.documentService.getAll().subscribe(response => {

        this.documents = response.documents;
        this.documents.forEach(t => {
          let type: any= t.type;
          if(type.libelle.includes("CM")){

            this.lesDocuments.push(t);
          }
        });

      }, error => {
        this.message = { severity: 'error', summary: error.error };
        console.error(JSON.stringify(error));
      });
    }

     // rechercher les documents

  searchRapport(event?: LazyLoadEvent) {
    this.lesDocuments=[];
    const data={
      jour: this.search.jour ? this.search.jour:0,
      anne: this.search.anne ? this.search.anne: 0,
      numero:this.search.numero ? this.search.numero:"",
      mois:this.search.mois ? this.search.mois: 0
    }
    this.isOpInProgress = true;
    console.log(data);
    this.documentService.filterRapport(data).subscribe(response => {
      this.lesDocuments = response;
      console.log("documents",this.lesDocuments);
      this.isOpInProgress = false;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
  // type de textes fondamentaux

  getTDCommunautaire(event?: LazyLoadEvent) {

    this.typeDocumentService.getAllTDCommunautaire().subscribe(response => {
      this.typeDocuments = response.typeDocuments;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
  // afficher la liste des secteurs

  chargerSecteurs(event?: LazyLoadEvent) {
    this.secteursService.getAllSecteurs().subscribe(response => {
      this.secteurs = response.listeSecteurs;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  //Détail
  onInfo(selection: any) {
    this.document = Object.assign({}, selection);
    console.log("doc", this.document)
    this.clearDialogMessages();
    this.documentDetail = true;

  }
  // Errors

  handleError(error: HttpErrorResponse) {
    console.error(`Processing Error: ${JSON.stringify(error)}`);
    this.isOpInProgress = false;
    this.dialogErrorMessage = error.error.title;
  }
  // Messages

  clearDialogMessages() {
    this.dialogErrorMessage = null;
  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }

}
