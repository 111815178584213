<div>

  <p-card>
    <!-- ToolBar -->
    <div class="p-d-flex">
      <app-crud-toolbar [enableCreate]="enableCreate" (create)="onCreate()">
      </app-crud-toolbar>

      <div class="p-ml-auto">
        <p-message *ngIf="message" [severity]="message.severity" [text]="message.summary"></p-message>
        <p-progressSpinner *ngIf="isOpInProgress" strokeWidth="4" [style]="{width: '50px', height: '50px'}">
        </p-progressSpinner>
      </div>
    </div>
    <p-divider></p-divider>
    <p-table #dt [value]="structures" [paginator]="true" [rows]="recordsPerPage" [loading]="isLoading"
      (onLazyLoad)="loadAllStructures($event)" [totalRecords]="totalRecords" [globalFilterFields]="['sigle','libelle']"
      currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements" [showCurrentPageReport]=true
      [rowsPerPageOptions]="[10, 20,30]" styleClass="p-datatable-sm">

      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col" style="padding: 8px; margin-left: 8px">
            <span>Liste des services</span>
          </div>
          <div class="col text-right">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($any($event.target)!.value, 'contains')"
                placeholder="Recherche..." />
            </span>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="sigle">Sigle
            <p-sortIcon field="sigle"></p-sortIcon>
          </th>
          <th pSortableColumn="libelle">Libellé
            <p-sortIcon field="libelle"></p-sortIcon>
          </th>
          <th pSortableColumn="telephone">Téléphone
            <p-sortIcon field="telephone"></p-sortIcon>
          </th>
          <th pSortableColumn="emailResp">E-mail
            <p-sortIcon field="emailResp"></p-sortIcon>
          </th>
          <th style="text-align: center">Actions</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-structure>
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="structure">
          <td>{{structure.sigle}} </td>
          <td>{{structure.libelle}} </td>
          <td>{{structure.telephone}} </td>
          <td>{{structure.emailResp}} </td>
          <td>
            <app-actions-toolbal-iud (info)="onInfo(structure)" [enableBtnInfo]="enableBtnInfo"
              (edit)="onEdit(structure)" [enableBtnEdit]="enableBtnEdit" (delete)="onDelete(structure)"
              [enableBtnDelete]="enableBtnDelete">
            </app-actions-toolbal-iud>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>

  <p-dialog [(visible)]="showDialog" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}" [maximizable]="true">

    <ng-template pTemplate="header">
      <i class="{{isEditing() ? 'pi-pencil'  : 'pi-plus'}} pi p-mr-1"></i>
      <span class="mr-auto">
        {{structure.id ? ' Modification des informations du' : ' Ajouter un service'}}
      </span>
    </ng-template>

    <p-progressBar *ngIf="isDialogOpInProgress" mode="indeterminate"></p-progressBar>
    <p-message *ngIf="dialogErrorMessage" severity="error" [text]="dialogErrorMessage"></p-message>
    <p-divider></p-divider>

    <!-- Form -->
    <form (ngSubmit)="save()" #dtf="ngForm">
      <!-- <div class="p-fluid grid">
        <div class="p-fluid p-col-12 col-md-6">
          <label for="type">Type <span class="text-danger">*</span></label>
          <p-dropdown [(ngModel)]="structure.type" optionLabel="libelle" optionValue="type" inputId="type"
            [options]="Typestructures" name="type" [filter]="true" filterBy="libelle" [showClear]="true"
            placeholder="Selectionner...">
          </p-dropdown>
        </div> -->

      <div class="p-fluid p-col-12 col-md-12">
        <label for="sigle">Sigle <span class="text-danger">*</span></label>
        <input id="sigle" name="sigle" #sigle="ngModel" [(ngModel)]="structure.sigle" pInputText required />
        <div class="p-error" *ngIf="sigle.invalid && (sigle.dirty || sigle.touched) && sigle.errors">
          <span>Veuillez renseigner le sigle</span>
        </div>
      </div>

      <div class="p-fluid p-col-12 col-md-12">
        <label for="libelle">Libellé <span class="text-danger">*</span></label>
        <input id="libelle" name="libelle" #libelle="ngModel" [(ngModel)]="structure.libelle" pInputText required />
        <div class="p-error" *ngIf="libelle.invalid && (libelle.dirty || libelle.touched) && libelle.errors">
          <span>Veuillez renseigner le libéllé</span>
        </div>
      </div>

      <!-- <div class="p-fluid p-col-12 col-md-6">
          <label for="niveau">Niveau <span class="text-danger">*</span> </label>
          <input id="niveau" type="number" name="niveau" #niveau="ngModel" [(ngModel)]="structure.niveau" pInputText
            required />
        </div> -->

      <div class="p-fluid p-col-12 col-md-12">
        <label for="telephone">Téléphone</label>
        <p-inputMask id="telephone" name="telephone" type="tel" #telephone="ngModel" [(ngModel)]="structure.telephone"
          mask="(+226) 99-99-99-99"></p-inputMask>
        <!-- <div class="p-error" *ngIf="telephone.invalid && (telephone.dirty || telephone.touched) && telephone.errors">
            <span>Veuillez renseigner le numéro de téléphone de la structure</span>
          </div> -->
      </div>

      <div class="p-fluid p-col-12 col-md-12">
        <label for="emailStruct">e-mail</label>
        <input id="emailStruct" name="emailStruct" #emailStruct="ngModel" [(ngModel)]="structure.emailStruct"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" pInputText />
      </div>

      <div class="p-fluid p-col-12 col-md-12">
        <label for="responsable">responsable</label>
        <input id="responsable" name="responsable" #responsable="ngModel" [(ngModel)]="structure.responsable"
          pInputText />
      </div>


      <div class="p-fluid p-col-12 col-md-12">
        <label for="emailResp">e-mail pro. responsable</label>
        <input id="emailResp" name="emailResp" #emailResp="ngModel" [(ngModel)]="structure.emailResp"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" pInputText />
        <!-- <div class="p-error" *ngIf="emailResp.invalid && (emailResp.dirty || emailResp.touched) && emailResp.errors">
            <span>Veuillez renseigner l'email du responsable</span>
          </div>  -->
      </div>

      <!-- <div class="p-fluid p-col-12 col-md-12">
          <label for="ministere">Ministère de tutelle <span class="text-danger">*</span> </label>
          <p-dropdown [(ngModel)]="structure.ministere" optionLabel="sigle" inputId="ministere" [options]="ministeres"
            name="ministere" [filter]="true" filterBy="sigle" [showClear]="true" placeholder="Selectionner le ministère ..." [disabled]="modifStructure">
          </p-dropdown>
        </div> -->

      <div class="p-fluid p-col-12 col-md-12">
        <label for="parent">Relève de</label>
        <p-dropdown [options]="structures" [(ngModel)]="structure.parent" optionLabel="libelle" inputId="parent"
          name="parent" placeholder="Selectionner la structure d'attache ..." [filter]="true" appendTo="body"
          [showClear]="true">
        </p-dropdown>
      </div>

      <div class="p-fluid p-col-12 col-md-12">
        <div> <label for="description">Description</label></div>
        <textarea id="description" name="description" #description="ngModel" [(ngModel)]="structure.description"
          rows="4" pInputTextarea></textarea>
      </div>


      <p-divider></p-divider>

      <div class="p-ml-auto text-right">
        <button type="reset" label="Annuler" (click)="showDialog=false"
          class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
        <button type="submit" label="{{isEditing() ? 'Enregistrer' : 'Ajouter'}}"
          icon="pi {{isEditing() ? 'pi-save' : 'pi-plus'}}"
          class="p-button-raised p-button-text {{isEditing() ? 'p-button-primary' : 'p-button-success'}}" pButton>
        </button>
      </div>
    </form>
  </p-dialog>




  <p-dialog [(visible)]="structureDetail" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
      <span class="p-mr-auto"> INFORMATIONS DETAILLEES DU SERVICE</span>
    </ng-template>
    <div class="row">
      <div class="col-md-6">
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Sigle :</span> {{structure.sigle}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold"> Libellé :</span> {{structure.libelle}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold"> Responsable :</span> {{structure.responsable}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Téléphone :</span> {{structure.telephone}}</span>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold"> Email structure :</span> {{structure.emailStruct}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold"> Email responsable :</span>{{structure.emailResp}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold"> Est rattachée au: </span> {{structure.parent?
              structure.parent.libelle : '-'}}</span>
          </li>
        </ul>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="p-ml-auto text-right">
      <button description="reset" label="Fermer" (click)="structureDetail=false"
        class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
    </div>
  </p-dialog>

</div>