import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent,Message } from 'primeng/api';
import { TypeDocument } from 'src/app/models/parametrage/type-document';
import { AuthenticationService } from 'src/app/services/parametrage/authentication.service';
import { TypeDocumentService } from 'src/app/services/parametrage/type-document.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-type-document',
  templateUrl: './type-document.component.html',
  styleUrls: ['./type-document.component.scss']
})
export class TypeDocumentComponent implements OnInit {

  @ViewChild('dtf') form!: NgForm;

  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  typeDocuments!: TypeDocument[];
  typeDocument: TypeDocument= {};
  enableCreate = true;
  enableBtnInfo = true;
  enableBtnEdit = true;
  enableBtnDelete=true;
  isLoading!: boolean;
  isOpInProgress!: boolean;
  isDialogOpInProgress!: boolean;
  showDialog = false;
  exempleDetail: boolean=false;
  message: any;
  dialogErrorMessage: any;
  permissions : any;
  constructor(
    private typeDocumentService: TypeDocumentService,
    private confirmationService: ConfirmationService,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.load();
    this.permissions=this.authService.getPrivilege();
    this.enableBtnDelete=this.authService.checkPermission(this.permissions, ['ROLE_ADMIN']);
  }

  isEditing() {
    return !!this.typeDocument.id;
  }
 // Affichage

  load(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.typeDocumentService.getAllTDocument().subscribe(response => {
      this.isLoading = false;
      this.typeDocuments = response.typeDocuments;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

   //Détail
   onInfo(selection: any) {
    this.typeDocument = Object.assign({}, selection);
    this.clearDialogMessages();
    this.exempleDetail=true;
  }
  //Creation

  save() {
    if (this.typeDocument.id) {
      this.edit();
    } else {
      this.create();
    }
  }

  onCreate() {
    this.typeDocument = {};
    this.clearDialogMessages();
    this.form.resetForm();
    this.showDialog = true;
  }
  create() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
    this.typeDocumentService.create(this.typeDocument).subscribe(response => {
      if (this.typeDocuments.length !== this.recordsPerPage) {
        this.typeDocuments.push(response);
        this.typeDocuments = this.typeDocuments.slice();
      }
      this.totalRecords++;
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'type de document créé avec succès' });
    }, error => this.handleError(error));
  }

     // Edit

     onEdit(selection: any) {
      this.typeDocument = Object.assign({}, selection);
      this.clearDialogMessages();
      this.showDialog = true;
    }
  edit() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
    this.typeDocumentService.update(this.typeDocument).subscribe(response => {
      let index = this.typeDocuments.findIndex(typeDocument => typeDocument.id === response.id);
      this.typeDocuments[index] = response;
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'type de document modifié avec succès' });
    }, error => this.handleError(error));
  }

     // Deletion

     onDelete(selection: any) {
      this.confirmationService.confirm({
        message: 'Etes-vous sur de vouloir supprimer cet type de document?',
        accept: () => {
          this.delete(selection);
        }
      });
    }
  
    delete(selection: any) {
      this.isOpInProgress = true;
      console.log("id",selection.id);
      this.typeDocumentService.delete(selection.id).subscribe(() => {
        this.typeDocuments = this.typeDocuments.filter(typeDocument => typeDocument.id !== selection.id);
        selection = null;
        this.isOpInProgress = false;
        this.totalRecords--;
        this.showMessage({
          severity: 'success',
          summary: 'Type de document supprimé avec succès',
        });
      },(error) => this.handleError(error)
    );
    }
  handleError(error: HttpErrorResponse) {
    console.error(`Processing Error: ${JSON.stringify(error)}`);
    this.isDialogOpInProgress = false;
    this.dialogErrorMessage = error.error.title;
  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }
    // Messages

    clearDialogMessages() {
      this.dialogErrorMessage = null;
    }

}