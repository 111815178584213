import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { ThemeLandingComponent } from 'src/app/theme/components/theme-landing/theme-landing.component';

import { LoginComponent } from '../../../theme/components/login/login.component';
import { AuthGuard } from '../../../theme/components/guard/auth.guard';

import { DhsStructureComponent } from './dhs-structure/dhs-structure.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'workspace/dashboard', component: ThemeLandingComponent, canActivate: [AuthGuard],

    children: [

      { path: 'dhsstructure', component: DhsStructureComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
