<!-- <div>
    <button pButton label="Ajouter" icon="pi pi-plus" class="p-button-raised p-button-text p-button-success mr-2"
      [disabled]="!enableCreate" (click)="fireCreate()">
    </button>
    <button pButton label="Editer" icon="pi pi-pencil" class="p-button-raised p-button-text p-button-primary mr-2"
      [disabled]="!enableEdit" (click)="fireEdit()">
    </button> -->
<!-- <button pButton label="Supprimer" icon="pi pi-trash" class="p-button-raised p-button-text p-button-danger"
      [disabled]="!enableDelete" (click)="fireDelete()">
    </button>
</div>  -->
<div>

    <button pButton pRipple type="button" icon="pi pi-eye" label="" pTooltip="Détail" class="p-button-raised p-button-info mr-2 " [hidden]="!enableBtnInfo" (click)="fireInfo()"></button>
    <button pButton pRipple type="button" icon="pi pi-pencil" label="" pTooltip="Modifier" class="p-button-raised p-button-success mr-2" [hidden]="!enableBtnEdit" (click)="fireEdit()"></button>
    <button pButton pRipple type="button" icon="pi pi-trash" label="" class="p-button-raised p-button-danger mr-2" pTooltip="Supprimer" [hidden]="!enableBtnDelete" (click)="fireDelete()"></button>
    <button pButton pRipple type="button" icon="pi pi-download" label="" class="p-button-raised p-button-success mr-2" pTooltip="Telecharger" [hidden]="!enableBtnDownload" (click)="fireDownload()"></button>
    <button pButton pRipple type="button" icon="pi pi-bookmark" label="Privileges" class="p-button-raised p-button-secondary mr-2" pTooltip="Privileges" [hidden]="!enableBtnPrivilege" (click)="firePrivilege()"></button>
    <button pButton pRipple type="button" icon="pi pi-lock" label="" class="p-button-raised p-button-secondary mr-2" pTooltip="Cloturer" [hidden]="!enableBtnClose" (click)="fireClose()"></button>
    <button pButton pRipple type="button" icon="pi pi-send" label="" class="p-button-raised p-button-secondary mr-2" pTooltip="Renvoie du mail" [hidden]="!enableBtnEmail" (click)="fireEmail()"></button>
    <button pButton pRipple type="button" icon="pi pi-chart-line" label="" class="p-button-raised p-button-success mr-2" pTooltip="Evaluer" [hidden]="!enableBtnEval" (click)="fireEvaluer()"></button>
    <button pButton pRipple type="button" icon="pi pi-undo" label="" class="p-button-raised p-button-success mr-2" pTooltip="Changer" [hidden]="!enableBtnChanger" (click)="fireChanger()"></button>
    <button pButton pRipple type="button" icon="pi pi-check" label="" class="p-button-raised p-button-success mr-2" pTooltip="Modifier profil" [hidden]="!enableBtnEditProfil" (click)="fireEditProfil()"></button>
</div>
