import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu-public',
  templateUrl: './menu-public.component.html',
  styleUrls: ['./menu-public.component.scss']
})
export class MenuPublicComponent implements OnInit {
  items: MenuItem[]=[];
  constructor() { }

  ngOnInit(): void {
    this.items = [
      {
          label:'Accueil',
          routerLink: ['/accueil']

      },
      {
          label:'Textes nationaux',
          routerLink:['textesFondamentaux'],
          items: [
            {
            label: 'Textes Fondamentaux',
            routerLink:['textesFondamentaux'],
           },
          //  {
          //   label: 'Décisionc CC',
          //   routerLink:['textesfondamentaux'],
          //  },
           {
            label: 'Textes Législatifs',
            routerLink:['textesLegislatifs']
           },
           {
            label: 'Textes règlementaires',
            routerLink:['textesReglementaires']
           }

          ]
      },
      {
          separator:true
      },
      {
          label:'Textes communautaires',
          routerLink: ['textesCommunautaires'],
          //items: [
            // {
            //   label: 'Traités',
            //   routerLink:['/institution'],
            // },
            // {
            //   label: 'règlements',
            //   routerLink:['/institution'],
            // },
            // {
            //   label: 'Actes uniformes',
            //   routerLink:['/institution'],
            // }
         // ]


      },
      {
          label:'Textes internationaux',
          routerLink: ['textesInternationaux'],
          // items: [
          //   {
          //     label: 'Traités',
          //     routerLink:['/institution'],
          //   },
          //   {
          //     label: 'Accords',
          //     routerLink:['/institution'],
          //   },

          //   {
          //     label: 'Conventions',
          //     routerLink:['/institution'],
          //   }
          // ]
      },
      {
          label:'Politiques et Stratégies',
          routerLink: ['politiqueStrategies']

      },
      {
        label:'Conseils des ministres',
        // routerLink: ['conseilsMinistres'],
        url: 'https://sggcm.gov.bf/conseil-des-ministres/',
        target: '_blank',


    },
    {
      label:'Autres',
      routerLink: ['textesAutres']


  },
  {
    label:'Nous contacter',
    routerLink: ['contactezNous']
  }

  ];
  }

}
