<div class="contaner" id="login-section">
	<div class="screen">
		<div class="screen__content">
			<div class="text-center">
				<img class="text-center mt-3" src="assets/images/armoirie.png" routerLink="/workspace" alt="Image"
					width="" height="80px" />
			</div>
			<form class="login" [formGroup]="form" name="form" (ngSubmit)="form.valid && onSubmit()" novalidate>
				<div class="row">
					<div class="col-md-12">
						<div style="text-align: center" class="col-lg-12 col-md-12 col-sm-12" [hidden]="true">
							<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
						</div>
						<div class="alert alert-danger alert-dismissible fade show" role="alert" [hidden]="true">
							<strong>Echec !</strong>{{messageErreur}}
							<button type="button" class="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				</div>
				<div class="login__field">
					<i class="login__icon pi pi-user"></i>
					<input name="password" formControlName="password" type="password" class="login__input"
						placeholder="Mot de passe" required>
				</div>
				<div class="login__field">
					<i class="login__icon pi pi-lock"></i>
					<input name="confirm_password" formControlName="confirm_password" type="password"
						class="login__input" placeholder="Confirmer mot de passe" required>
					<div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
						<div *ngIf="f.password.errors"> Veillez renseigner votre mot de passe!</div>
					</div>
				</div>
				<div class="form-group">
					<input name="recover-submit" class="button login__submit button__text" [disabled]="!form.valid"
						value="Valider" type="submit">

				</div>
				<!-- <button class="button login__submit" (click)="null">
					<span class="button__text">Valider</span>
					<i class="button__icon pi pi-lock-open"></i>
				</button> -->

			</form>
		</div>
		<div class="screen__background">
			<span class="screen__background__shape screen__background__shape4"></span>
			<span class="screen__background__shape screen__background__shape3"></span>
			<span class="screen__background__shape screen__background__shape2"></span>
			<span class="screen__background__shape screen__background__shape1"></span>
		</div>
	</div>
</div>