<div class="hor-header header" id="_header">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4 mt-3">

                <a href="/">
                    <img src="assets/images/logo-legi-bf.png" class="logo-img" id="" height="80" alt="BURKINA FASO">
                </a>
            </div>
            <div class="col-12 col-md-4 legi-section">
                <h4 class="text-center">LégiBurkina</h4>
            </div>
            <div class="col-12 col-md-4 flag-section">
                <a href="/">
                    <img src="assets/images/flagbf.png" id="" class="flag-img" alt="BURKINA FASO">
                </a>
            </div>

        </div>
    </div>
</div>


<div id="menu-section">
    <div class="container">
        <app-menu-public></app-menu-public>

    </div>
</div>

<div class="container"></div>
<router-outlet></router-outlet>


<app-footer-public></app-footer-public>