export interface StateDoc {
  nombre?:number;
    type?:string
}

export class StateDoc implements StateDoc{
  constructor(
    public nombre?: number,
    public type?: string,
  ){}
}
