import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetAllSiteExterneResponse, SiteExterne } from 'src/app/models/parametrage/site-externe';
import { environment } from 'src/environments/environment';
const resourceUrl = environment.sitesExternesResource;
@Injectable({
  providedIn: 'root'
})
export class SiteExterneService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<GetAllSiteExterneResponse> {
    return this.http.get(resourceUrl, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let exemplesResponse: GetAllSiteExterneResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          siteExternes: response.body as SiteExterne[]
        };
        return exemplesResponse;
      }));
  }

  create(request: SiteExterne): Observable<SiteExterne> {
    return this.http.post(resourceUrl, request);
  }

  update(exemple: SiteExterne): Observable<SiteExterne> {
    return this.http.put(resourceUrl, exemple);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${resourceUrl}/${id}`);
  }
}
