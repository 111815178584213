import { TypeStateVo } from './../../../../models/vo/document.type.state';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LazyLoadEvent, Message } from 'primeng/api';
import { StatDashboard } from 'src/app/models/parametrage/statDashboard';
import { StatDashoardService } from './../../../../services/parametrage/stat-dashoard.service';
import { TypeDocument } from './../../../../models/parametrage/type-document';
import { TypeDocumentService } from './../../../../services/parametrage/type-document.service';
import { Search, Search2 } from './../../../../models/parametrage/search';
import { DocumentService } from 'src/app/services/parametrage/document.service';
import {StateDoc} from "../../../../models/parametrage/statDoc";

@Component({
  selector: 'app-dhs-global',
  templateUrl: './dhs-global.component.html',
  styleUrls: ['./dhs-global.component.scss']
})
export class DhsGlobalComponent implements OnInit {
  private documentStyle = getComputedStyle(document.documentElement);
  private textColor = this.documentStyle.getPropertyValue('--text-color');
  private textColorSecondary = this.documentStyle.getPropertyValue('--text-color-secondary');
  private surfaceBorder = this.documentStyle.getPropertyValue('--surface-border');

  @ViewChild('dtf') form!: NgForm;

  // ministere: Ministere = {};
  // ministeres: Ministere[] = [];
  // ministereID?: number;
  resumerData?: StatDashboard;
  globalLibelle: string = "";

  currentStructureId?: number;
  currentMinistereId?: number;
  currentExerciceId?: number;

  // construction des figures
  // == courbes linéaires
  globalLinesData: any;
  basicOptions: any;

  searchStateFilter: Search2 = new Search2();

  options:any = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
        legend: {
            labels: {
                color: this.textColor
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: this.textColorSecondary,
                font: {
                    weight: 500
                }
            },
            grid: {
                color: this.surfaceBorder,
                drawBorder: false
            }
        },
        y: {
            ticks: {
                color: this.textColorSecondary
            },
            grid: {
                color: this.surfaceBorder,
                drawBorder: false
            }
        }
    }
};

  // == baton empilé
  stackedData: any;
  stackedOptions: any;

  // construction du tableau
  //dataBound!: StructureActiviteData[];
  typeDocuments!:TypeDocument[];
  message: any;
  isDialogOpInProgress!: boolean;
  dialogErrorMessage: any;
  loading: boolean = false;
  showDialog: boolean = false;
  timeoutHandle: any;
  data:any;
  dataMensuel:any;
  search:Search2={};
  allUser:any[]=[];
  allDDowload:any[]=[];
  allDocument:any[]=[];
  aUser:any;
  aDowloaDoc:any;
  aDoc:any;
  statDocs: StateDoc[] = [];
  nombreDocs: number[] = [];
  libelleDocs: string[] = [];
  downloadData: any;
  yearsFromDb: any =[];
  constructor(
    private statService: StatDashoardService,
    private typeDocumentService: TypeDocumentService,
    private documentService: DocumentService
  ) {


  this.dataMensuel = {
    labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet','Aout','Septembre','Octobre','Novembre','Décembre'],
    datasets:[
        {
            label: 'Documents téléchargés',
            backgroundColor: '#42A5F5',
            data: [65, 59, 80, 81, 56, 55, 40,78,45,89,13]
        },

    ]
}
  }

  ngOnInit(): void {
    this.load();
    this.loadAllTypeDocument();
    this.searchStateFilter.anne=(new Date()).getFullYear();

    this.getNbDowloadbyType();
   // this.getTotalDocByType();
    this.statService.getNbreUtilisateur().subscribe(data=> {
      this.allUser=data;
      this.allUser.forEach(element => {
        this.aUser=element.count;
      });

    });
  }

  getTotalDocByType()
  {
    this.documentService.getTotalDocByType().subscribe(response => {
      this.statDocs = response.body!;
      console.warn("liste stat",this.statDocs);
      this.typeDocuments.forEach(item=>{
        const findType = this.statDocs.find(doc=>doc.type ==item.libelle);
        if(findType == undefined){
          const statDocument: StateDoc = new StateDoc();
          statDocument.type = item.libelle;
          statDocument.nombre = 0;
          this.statDocs.push(statDocument);
        }
      });
      this.statDocs.forEach(elem=>{
        this.nombreDocs.push(elem.nombre!);
        this.libelleDocs.push(elem.type!);
      });
      this.data = {
        labels: this.libelleDocs,
        datasets: [
          {
            label: 'Documents',
            backgroundColor: '#42A5F5',
            data: this.nombreDocs
          },
        ]
      }
    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

  getNbDowload(search:Search){
    console.log("sear", search)
    this.statService.getDocumentBundle(search.anne!).subscribe(data=> {
      this.allDDowload=data;
      console.log("nombre de doc",this.allDDowload)
      this.allDDowload.forEach(element => {
        this.aDowloaDoc=element.count;
      });

    });
  }



  getNbDowloadbyType(fillYears = true){
    this.statService.getDocumentDownload(this.searchStateFilter).subscribe( (data:TypeStateVo[]) => {
      console.log('DOWNLOAD STATE ', data);
      console.warn('RESPONSE DATA', data);
      this.doChartDataConfiguration(data || [], fillYears);
    });
  }
  doChartDataConfiguration(data: TypeStateVo[], fillYears = true) {
    console.error("Testet ", data);
    if(fillYears) this.yearsFromDb = Array.from(new Set(data.map(x => x.annee)));
    let dataColumns:number[] = [0,0,0,0,0,0,0,0,0,0,0,0];
    for (let index = 0; index < data.length; index++) {
      const mois = data[index].mois as number -1;
      dataColumns[mois] = data[index].nombre as number;

    }
    this.downloadData = {

      labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet','Aout','Septembre','Octobre','Novembre','Décembre'],
      datasets:[
          {
              label: 'Etat des téléchargement de documents',
              backgroundColor: '#42A5F5',
              data: dataColumns
          },

      ]
  }
  }

  // NOMBRE DE DOCUMENTS

  load(event?: LazyLoadEvent) {

    this.documentService.getAll().subscribe(response => {
      this.allDocument = response.documents;
       this.aDoc=this.allDocument.length;
    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }

//   selectAnne(event: any) {
//     let selectedAnne=4;
//     this.statService.getDocumentBundle(selectedAnne).subscribe(
//         (response) => {
//             //this.resumerData = response;
//             this.loadStatsData(response);
//         },
//         (error) => {
//             this.message = { severity: 'error', summary: error.error };
//             console.error(JSON.stringify(error));
//         }
//     );

// }

  loadStatsData(data?: StatDashboard) {

    this.resumerData = data;

    let libs: string[] = [];
    let taux: number[] = [];
    let couts: number[] = [];

    // if(this.resumerData){
    //     if(this.resumerData.periodes){
    //         libs = this.resumerData.periodes;
    //     }

    //     if(this.resumerData.finances){
    //         couts = this.resumerData.finances;
    //     }

    //     if(this.resumerData.physiques){
    //         taux = this.resumerData.physiques;
    //     }
    // }

    this.globalLinesData = {
        labels:['T1', 'T2', 'T3', 'T4'],
        datasets: [
            {
                label: 'Nombre de documents',
                data:[65, 59, 80, 81],
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            },

        ]
    };
}



// Errors
handleError(error: HttpErrorResponse) {
    console.error(`Processing Error: ${JSON.stringify(error)}`);
    this.isDialogOpInProgress = false;
    this.dialogErrorMessage = error.error.title;
}

// Messages

clearDialogMessages() {
    this.dialogErrorMessage = null;
}

showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
        this.message = null;
    }, 5000);
}

  loadAllTypeDocument() {
    this.typeDocumentService.getAllTDocument().subscribe(response => {
      this.typeDocuments = response.typeDocuments as TypeDocument[];
      console.log("TETSTE TYPe ", this.typeDocuments)
      // this.searchStateFilter.type = ""+ this.typeDocuments[0].id ;

      console.log("TETSTE TYPe°°°", this.searchStateFilter)
      this.getTotalDocByType();
    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
}
