import { SecteursComponent } from './secteurs/secteurs.component';
import { NgModule, Component } from '@angular/core';
import { MinistereStructureComponent } from './ministere-structure/ministere-structure.component';
import { StructureComponent } from './structure/structure.component';
import { RouterModule, Routes } from '@angular/router';
import { ThemeLandingComponent } from 'src/app/theme/components/theme-landing/theme-landing.component';
import { AgentComponent } from './agent/agent.component';
import { LoginComponent } from '../../../theme/components/login/login.component';
import { AuthGuard } from '../../../theme/components/guard/auth.guard';
import { DhsStructureComponent } from '../dashboard/dhs-structure/dhs-structure.component';
import { ResetPasswordComponent } from 'src/app/theme/components/reset-password/reset-password.component';
import { ExempleComponent } from './exemple/exemple.component';
import { JournalOfficielComponent } from './journal-officiel/journal-officiel.component';
import { SiteExterneComponent } from './site-externe/site-externe.component';
import { TypeDocumentComponent } from './type-document/type-document.component';
import { CompteComponent } from './compte/compte.component';
import { ProfilComponent } from './profil/profil.component';
import { PrivilegeComponent } from './privilege/privilege.component';
import { DocumentComponent } from './document/document.component';
import { LandingPageComponent } from 'src/app/theme/components/landing-page/landing-page.component';
import { HomePageComponent } from 'src/app/theme/components/home-page/home-page.component';
import { TextesFondamentauxComponent } from '../../public/pages/textes-fondamentaux/textes-fondamentaux.component';
//import { TextesLegislatifsComponent } from '../../public/pages/textes-legislatifs/textes-legislatifs.component';
import { TextesReglementairesComponent } from '../../public/pages/textes-reglementaires/textes-reglementaires.component';
// import { TextesReglementairesComponent } from 'src/app/theme/components/home-page/textes-reglementaires/textes-reglementaires.component';
import { TextesCommunautairesComponent } from '../../public/pages/textes-communautaires/textes-communautaires.component';
import { TextesInternationauxComponent } from '../../public/pages/textes-internationaux/textes-internationaux.component';
import { ConseilsMinistreComponent } from '../../public/pages/conseils-ministre/conseils-ministre.component';
import { TextesLegislatifComponent } from '../../public/pages/textes-legislatif/textes-legislatif.component';

import { TextesAutresComponent } from '../../public/pages/textes-autres/textes-autres.component';
import { ContactezNousComponent } from '../../public/pages/contactez-nous/contactez-nous.component';

import { PolitiqueStrategieComponent } from '../../public/pages/politique-strategie/politique-strategie.component';

import { PubliciteComponent } from './publicite/publicite.component';
import { DhsGlobalComponent } from '../dashboard/dhs-global/dhs-global.component';






const routes: Routes = [
  { path:'', redirectTo:'/accueil',pathMatch:'full'},
  { path:'accueil', component: LandingPageComponent,

// },

  // partie publique
  // { path:'', redirectTo:'/login',pathMatch:'full'},
  // { path:'login', component: LoginComponent},

  // { path: 'accueil', component: LandingPageComponent,
  children: [
    { path: '', component: HomePageComponent },
    { path: 'textesFondamentaux', component: TextesFondamentauxComponent},
    { path: 'textesReglementaires', component: TextesReglementairesComponent },
    { path: 'textesLegislatifs', component: TextesLegislatifComponent },
    { path: 'textesCommunautaires', component: TextesCommunautairesComponent },
    { path: 'textesInternationaux', component: TextesInternationauxComponent },
    { path: 'conseilsMinistres', component: ConseilsMinistreComponent },
    {
      path: 'politiqueStrategies', component: PolitiqueStrategieComponent,
     },
    { path: 'textesAutres', component: TextesAutresComponent },

    { path: 'contactezNous', component: ContactezNousComponent}

  ]

  },



  // partie administration

  { path: 'workspace', component: ThemeLandingComponent,canActivate: [AuthGuard],
    children: [
      { path:'', component: DhsGlobalComponent },
      { path:'changer-mot-de-passe', component: ResetPasswordComponent},
      {
        path: 'exemple',
        component: ExempleComponent,
      },
      {
        path: 'siteExterne',
        component: SiteExterneComponent
      },
      {
        path: 'secteurs',
        component: SecteursComponent,
      },
      {
        path: 'journalOfficiel',
        component: JournalOfficielComponent,
      },
      {
        path: 'typeDocument',
        component: TypeDocumentComponent,
      },
      {
        path: 'agent',
        component: AgentComponent,
      },
      {
        path: 'structure',
        component: StructureComponent,
      },

      {
        path: 'publicite',
        component:PubliciteComponent,
      },
      {
        path: 'compte',
        component: CompteComponent,
      },
      {
        path: 'profil',
        component: ProfilComponent,
      },

      {
        path: 'privilege',
        component: PrivilegeComponent,
      },

      {
        path: 'stat', component: DhsGlobalComponent,
      },

       {
        path: 'document', component: DocumentComponent,
       },

  ]},

  { path:'/login', redirectTo:'/login'},
  { path:'login', component: LoginComponent},
  { path: "**", redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule],
})
export class SettingsRoutingModule { }
