
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
//import { GetAllPubliciteResponse, Document } from 'src/app/models/parametrage/document';
import { Search } from 'src/app/models/parametrage/search';
import { DocumentSearch, GlobalSearch } from './../../models/vo/document-search';
import { GetAllPubliciteResponse, Publicite } from 'src/app/models/parametrage/publicite';
import { ContactezNous } from 'src/app/models/parametrage/contactezNous';
const resourceUrl = environment.imageResource;
const contactUrl= environment.contactRessource;
const resourceGFDUrl= environment.globalSearchDocRessource;
const ressourceFDUrl=environment.searchDocRessource;
const ressourceFDAutresUrl=environment.searchDocAutresRessource;

@Injectable({
  providedIn: 'root'
})
export class PubliciteService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<GetAllPubliciteResponse> {
    // return this.http.get(`assets/data/publicite.json`, { observe: 'response' })
    return this.http.get(resourceUrl, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let publicitesResponse: GetAllPubliciteResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          publicites: response.body as Publicite[]
        };
        return publicitesResponse;
      }));
  }

  filterPublicite(search: Search) : Observable<any> {
    return this.http.post(ressourceFDUrl, search);
  }

  // filterAutresDocument(search: Search) : Observable<any> {
  //   return this.http.post(ressourceFDAutresUrl, search);
  // }

  globalfilterDocument(search: GlobalSearch) : Observable<any> {
    return this.http.post(resourceGFDUrl, search);
  }

  create(request: any): Observable<Publicite> {
    return this.http.post(resourceUrl, request);
  }
  saveMessage(request: any): Observable<ContactezNous> {
    return this.http.post(contactUrl, request);
  }
  // download(request: string): Observable<any> {
  //   return this.http.post(resourceUrl+'/download', request );
  // }

  // download(request: string): any {
  //   return this.http.post(resourceUrl+'/download', request , {responseType: 'blob'});
  // }


  // download(url:string):Observable<Blob>
  // {
  //   // return this.http.get(resourceUrl+'download/'+name,{responseType: 'blob'});
  //   return this.http.post(resourceUrl+'/download', "C:\\Users\\HP\\Legiburkina-files\\TYPE1\\ingenieur-trav-info-genie-logiciel.pdf" , {responseType: 'blob'});
  // }

  download(url:string):Observable<Blob>
  {
    return this.http.get(resourceUrl+'/download/'+url,{responseType: 'blob'});
  }

  update(Publicite: Publicite): Observable<Publicite> {
    return this.http.put(resourceUrl+'/update', Publicite);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${resourceUrl}/${id}`);
  }

  upload(file: File): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    return this.http.post<Publicite>(`${resourceUrl}/upload-publicite-image`, formData, { observe: 'response' });
  }

  save(request: Publicite): Observable<Publicite> {
    return this.http.post<Publicite>(`${resourceUrl}/save`, request);
  }

  async visualiser(fileName: string):Promise<string>{
    console.warn("filePPP",fileName);
    const resp = await this.getFile(fileName).toPromise().catch(e=>{
      console.warn("ERROR",e.message());
    });
    if(resp){
      const file = new Blob([resp],{type: 'image/png'});
      console.warn("AAAAA", URL.createObjectURL(file))
      return URL.createObjectURL(file);
    }else{
      return  '';
    }
  }

  private getFile(fileName: string):Observable<any>{
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
    };

    const formData: FormData = new FormData();
    formData.append('fileName',fileName);
    return this.http.post(`${resourceUrl}/fichier/recuperer-image`,formData,httpOptions);
  }

  findListe(): Observable<HttpResponse<Publicite[]>> {
    return this.http.get<Publicite[]>(`${resourceUrl}/list`, { observe: 'response' });
  }
}
