<div>
  <p-card>
  <!-- ToolBar -->

    <div class="p-d-flex">
      <app-crud-toolbar [enableCreate]="enableCreate" (create)="onCreate()">
      </app-crud-toolbar>
      <div class="p-ml-auto">
        <p-message *ngIf="message" [severity]="message.severity" [text]="message.summary"></p-message>
        <p-progressSpinner *ngIf="isOpInProgress" strokeWidth="4" [style]="{width: '50px', height: '50px'}">
        </p-progressSpinner>
      </div>
    </div>
      <p-divider></p-divider>

      <p-table #dt [value]="documents" [paginator]="false"
          [globalFilterFields]="[
          'reference',
          'date',
          'document',
          'secteur',
          'type',
          'description'
        ]"
        styleClass="p-datatable-sm">

          
          //nabi
           <!-- [rows]="recordsPerPage" [loading]="isLoading" (onLazyLoad)="load($event)" [totalRecords]="totalRecords"
          [globalFilterFields]="['reference','date','reference','secteur','type','description']"
          currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
          [showCurrentPageReport]=true [rowsPerPageOptions]="[10, 20,50,100]" -->
          //nabi
          <ng-template pTemplate="caption">
              <div class="row">
                  <!-- titre -->
                  <div class="col" style="padding: 8px; margin-left: 8px">
                      <span>Liste des documents</span>
                  </div>

                  <!-- volet recherche -->
                  <div class="col text-right">
                      <span class="p-input-icon-left">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text"
                              (input)="dt.filterGlobal($any($event.target)!.value, 'contains')"
                              placeholder="Recherche..." />
                      </span>
                  </div>
              </div>
          </ng-template>

          <ng-template pTemplate="header">
              <tr>
                <!-- <th pSortableColumn="id">Id
                  <p-sortIcon field="id"></p-sortIcon>
                </th> -->
                <th pSortableColumn="type">Type
                  <p-sortIcon field="type"></p-sortIcon>
                </th>
                  <th pSortableColumn="reference">Reference
                      <p-sortIcon field="reference"></p-sortIcon>
                  </th>
                  <th pSortableColumn="date">Date
                    <p-sortIcon field="date"></p-sortIcon>
                </th>
                <!-- <th pSortableColumn="secteur">Secteur
                  <p-sortIcon field="secteur"></p-sortIcon>
                </th> -->
                  <th pSortableColumn="description">Objet
                      <p-sortIcon field="description"></p-sortIcon>
                  </th>

                  <th>
                      Actions
                  </th>
              </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-document>
              <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="document">
                  <!-- <td>{{document.id}} </td> -->
                  <td>{{document.type.libelle}} </td>
                  <td>{{document.reference}} </td>
                  <td>{{document.date| date:'dd/MM/yyyy'}} </td>
                  <!-- <td>{{document.secteur.libelle}} </td> -->
                  <td>{{document.description}} </td>
                  <td style="width:20%">

                      <app-actions-toolbal-iud
                          (info)="onInfo(document)" [enableBtnInfo]="enableBtnInfo"
                          (edit)="onEdit(document)" [enableBtnEdit]="enableBtnEdit"
                          (delete)="onDelete(document)" [enableBtnDelete]="enableBtnDelete" >
                            <!-- (download)="voirDocument(document.url)" [enableBtnDownload]="enableBtnDownload"-->
                            

                      </app-actions-toolbal-iud>

                  </td>
              </tr>
          </ng-template>
          
      </p-table>
      <div class="flex justify-content-center mt-2">
        <p-paginator
          (onPageChange)="onPageChange($event)"
          [first]="first"
          [rows]="itemsPerPage"
          [totalRecords]="totalItems"
          currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
          [showCurrentPageReport]=true
        ></p-paginator>
      </div>
  </p-card>

<!-- formulaire d'ajout et de modification -->

<p-dialog [(visible)]="showDialog" [modal]="true" [maximizable]="true" appendTo="body"
  [style]="{width: '50vw'}" [contentStyle]="{height: '100%'}"
  [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
  <ng-template pTemplate="header">
    <i class="{{isEditing() ? 'pi-pencil' : 'pi-plus'}} pi p-mr-1"></i>
    <span class="mr-auto">
      {{document.id ? 'Modifier' : 'Ajouter'}} un document
    </span>
  </ng-template>
  <p-progressBar *ngIf="isDialogOpInProgress" mode="indeterminate"></p-progressBar>
  <p-message *ngIf="dialogErrorMessage" severity="error" [text]="dialogErrorMessage"></p-message>
  <p-divider></p-divider>


  <!-- Form -->
  <form (ngSubmit)="save()" #dtf="ngForm">



    <div class="grid">
      <div class="col-12 md:col-6 lg:col-6">
        <div class="p-fluid p-col-12 col-md-12 mb-2">
          <label for="abreviation">Type <span class="text-danger">*</span> :</label>
          <p-dropdown [options]="types" optionLabel="libelle" (onChange)="onChange($event)" id="type"
          name="type"
          placeholder="Selectionner le type"
          [(ngModel)]="document.type"
          rows="4"
          inputId="type" [filter]="true"
          filterBy="libelle" [showClear]="true">
         </p-dropdown>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <div class="p-fluid p-col-12 col-md-12 mb-2">
          <label for="reference">Reference document<span class="text-danger">*</span> :</label>
          <input id="reference" name="reference" #reference="ngModel" [(ngModel)]="document.reference" pInputText [disabled]="!champActif" />
        </div>

      </div>
    </div>

    <div class="grid">
      <div class="col-12 md:col-12 lg:col-12">
        <div class="p-fluid p-col-12 col-md-12 mb-2">
          <label for="date">Date<span class="text-danger">*</span> :</label>
          <p-calendar name="dateV" #date="ngModel" [(ngModel)]="dateV" dateFormat="dd/mm/yy"
          inputId="dateformat" [showIcon]="true" inputId="icon"></p-calendar>
        </div>
      </div>
      <!-- <div class="col-12 md:col-6 lg:col-6">
        <div class="p-fluid p-col-12 col-md-12 mb-2">
          <label for="abreviation">Secteur:</label>
          <p-dropdown [options]="secteurs" optionLabel="libelle" id="secteur"
          name="secteur"
          placeholder="Selectionner le secteur"
          [(ngModel)]="document.secteur"
          rows="4"
          inputId="secteur" [filter]="true"
          filterBy="libelle" [showClear]="true" optionDisabled="AGRI">
         </p-dropdown>
        </div>
      </div> -->
    </div>


      <div class="p-fluid p-col-12 col-md-12 mb-2">
        <label for="description">Objet<span class="text-danger">*</span> :</label>
        <textarea id="description" name="description" #description="ngModel"
            [(ngModel)]="document.description" rows="3" pInputText ></textarea>
      </div>


      <div class="grid">
        <div class="col-12 md:col-6 lg:col-6">

          <div class="p-fluid p-col-12 col-md-12 mb-2">
            <label for="abreviation">Journal officiel <span class="text-danger">*</span>:</label>
            <p-dropdown  [options]="journalOfficiels" optionLabel="numero" id="journalOfficiel"
            name="journalOfficiel"
            placeholder="Selectionner le journalOfficiel"
            [(ngModel)]="document.journalOfficiel"
            rows="4"
            inputId="journalOfficiel" [filter]="true"
            filterBy="numero,dateJournal,natureJournal" [showClear]="true">
            <ng-template let-journalOfficiel pTemplate="item">
              <div class="journal-officiel-item">
                <span>{{journalOfficiel.numero}} {{journalOfficiel.dateJournal | date:'dd/MM/yyyy'}} {{journalOfficiel.natureJournal}}</span>
                <!-- <span>{{journalOfficiel.dateJournal}}</span>
                <span>{{journalOfficiel.natureJournal}}</span> -->
              </div>
            </ng-template>
           </p-dropdown>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6">

          <div class="p-fluid p-col-12 col-md-12 mb-2">
            <label for="abreviation">Document parent:</label>
            <p-dropdown  [options]="documents" optionLabel="reference" id="parent"
            name="parent"
            placeholder="Selectionner le document parent"
            [(ngModel)]="document.parent"
            rows="4"
            inputId="parent" [filter]="true"
            filterBy="reference,date,type.libelle" [showClear]="true">
           </p-dropdown>
          </div>

        </div>
      </div>


      <div class="p-fluid p-col-12 col-md-12 mb-2">
        <label for="description"> Fichier <span class="text-danger">*</span></label>
          <p-fileUpload id="description" #description="ngModel" name="description" [(ngModel)]="document.description"
             mode="advanced" [showCancelButton]="false" [auto]="true" [customUpload]="true" accept="application/pdf"
            [showUploadButton]="false" chooseLabel="Selectionnez" (uploadHandler)="onSelectFile($event)"
            [required]="true">
            <ng-template pTemplate="content" let-files>
              <div *ngIf="!files.length" class="text-danger text-center p-p-5 app-yellow">
                <i class="pi pi-upload" style="font-size: 2rem"></i>
                <div>
                  <span>Selectionnez ou glissez et deplacez un fichier ici (format accepté pdf)</span>
                </div>
              </div>


            </ng-template>
          </p-fileUpload>

      </div>


    <!-- </div> -->

    <p-divider></p-divider>
    <div class="p-ml-auto text-right">
      <button type="reset" label="Annuler" (click)="showDialog=false"
        class="p-button-raised p-button-text p-button-success mr-2" pButton>
      </button>
      <button type="submit" [disabled]="!dtf.form.valid" label="{{isEditing() ? 'Enregistrer' : 'Ajouter'}}"
        icon="pi {{isEditing() ? 'pi-save' : 'pi-plus'}}"
        class="p-button-raised p-button-text {{isEditing() ? 'p-button-primary' : 'p-button-success'}}"
        pButton>
      </button>
    </div>
  </form>
</p-dialog>

<!-- formulaire d'affichage du détail -->


  <p-dialog [(visible)]="documentDetail" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">

      <span class="p-mr-auto"> DETAILS DOCUMENT</span>
    </ng-template>
    <div class="row">
      <div class="col-md-12">
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Type : </span>  {{document.type?.libelle}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">reference : </span>  {{document.reference}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Date : </span>  {{document.date | date:'dd/MM/yyyy'}}</span>
          </li>

          <!-- <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Secteur : </span>  {{document.secteur?.libelle}}</span>
          </li> -->
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Objet : </span>  {{document.description}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">JournalOfficiel : </span> {{document.journalOfficiel?.numero}} du {{document.journalOfficiel?.dateJournal | date:'dd/MM/yyyy'}} - {{document.journalOfficiel?.natureJournal }}</span>
          </li>
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Document parent : </span> {{document.parent?.reference}}  </span>
          </li>

        </ul>
     </div>

 </div>
 <p-divider></p-divider>
 <div class="p-ml-auto text-right">
     <button description="reset" label="Fermer" (click)="documentDetail=false" class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
 </div>
</p-dialog>

<!-- Confirmation -->
<app-confirmation></app-confirmation>
</div>
