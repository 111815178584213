import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {  getAllSecteursResponse, Secteurs } from 'src/app/models/parametrage/secteurs';
import { environment } from 'src/environments/environment';

const resourceUrl = environment.secteursResource;

@Injectable({
  providedIn: 'root'
})
export class SecteursService {

  constructor(
    private http: HttpClient
  ) {}

  getAllSecteurs(): Observable<getAllSecteursResponse> {
    return this.http.get(resourceUrl, { observe: 'response' })
    .pipe(map(response => {
          let secteursResponse: getAllSecteursResponse = {
         listeSecteurs: response.body as Secteurs[]
      };
      return secteursResponse;
    }));

  }
  create(secteurs: Secteurs): Observable<Secteurs> {
    return this.http.post(resourceUrl, secteurs);
  }

  update(secteurs: Secteurs): Observable<Secteurs> {
    return this.http.put(resourceUrl, secteurs);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${resourceUrl}/${id}`);
  }
  getAllSecteursFils(libelle:string): Observable<getAllSecteursResponse> {
    return this.http.get(`${resourceUrl}/${libelle}`, { observe: 'response' })
    .pipe(map(response => {
          let secteursResponse: getAllSecteursResponse = {
         listeSecteurs: response.body as Secteurs[]
      };
      return secteursResponse;
    }));
  }
}
