import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextesFondamentauxComponent } from './textes-fondamentaux/textes-fondamentaux.component';
import {InputNumberModule} from 'primeng/inputnumber';
import { DividerModule } from 'primeng/divider';
import { SearchPageComponent } from 'src/app/theme/components/search-page/search-page.component';
import {ButtonModule} from 'primeng/button';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';

import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageModule } from 'primeng/message';
import { TextesCommunautairesComponent } from './textes-communautaires/textes-communautaires.component';
import { TextesInternationauxComponent } from './textes-internationaux/textes-internationaux.component';
import { ConseilsMinistreComponent } from './conseils-ministre/conseils-ministre.component';
import { TextesAutresComponent } from './textes-autres/textes-autres.component';
import { TextesLegislatifComponent } from './textes-legislatif/textes-legislatif.component';
import { TextesReglementairesComponent } from './textes-reglementaires/textes-reglementaires.component';
import { CalendarModule } from 'primeng/calendar';
import {BreadcrumbModule} from 'primeng/breadcrumb';

import { ContactezNousComponent } from './contactez-nous/contactez-nous.component';
import { ToastModule } from 'primeng/toast';
import { PolitiqueStrategieComponent } from './politique-strategie/politique-strategie.component';
import { PaginatorModule } from 'primeng/paginator';



@NgModule({

  declarations: [
    TextesFondamentauxComponent,
    TextesCommunautairesComponent,
    TextesInternationauxComponent,
    ConseilsMinistreComponent,
    TextesAutresComponent,
    TextesLegislatifComponent,
    TextesReglementairesComponent,
    ContactezNousComponent,
    PolitiqueStrategieComponent
  ],

  imports: [
    InputNumberModule,
  ToastModule,
   CardModule,
   BreadcrumbModule,
    MessageModule,
    CalendarModule,
    ProgressSpinnerModule,
    CardModule,
    DividerModule,
    CommonModule,
    ButtonModule,
    DropdownModule,
    TableModule,
    DialogModule,
    PanelModule,
    FormsModule,
    DropdownModule,
    PaginatorModule,
  ]
})
export class PublicModule { }
