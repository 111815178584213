<div class="row mt-5">
  <div class="p-fluid p-col-12 col-lg-12">

  </div>
</div>

<div class="row">
  <!--<div class="col-12">
    <highcharts-chart
      [Highcharts] = "highcharts"
      [options] = "chartOptions"
      style = "width: 100%; height: 400px; display: block;">
    </highcharts-chart>
  </div>-->
</div>

<br><br>


<p-table class="mt-2" [value]="getKeys(countByType)" [tableStyle]="{ 'min-width': '100rem' }">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      Nombre de telechargement par type de document
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Type de document</th>
      <th>Nombre de téléchargement</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-doc>
    <tr>
      <td>{{ doc }}</td>
      <td>{{ countByType[doc] }}</td>
    </tr>
  </ng-template>
</p-table>

