<div>
    <p-card>
    <!-- ToolBar -->

      <div class="p-d-flex">
        <app-crud-toolbar [enableCreate]="enableCreate" (create)="onCreate()">
        </app-crud-toolbar>
        <div class="p-ml-auto">
          <p-message *ngIf="message" [severity]="message.severity" [text]="message.summary"></p-message>
          <p-progressSpinner *ngIf="isOpInProgress" strokeWidth="4" [style]="{width: '50px', height: '50px'}">
          </p-progressSpinner>
        </div>
      </div>
        <p-divider></p-divider>

        <p-table #dt [value]="publicites" [paginator]="true" [rows]="recordsPerPage" [loading]="isLoading"
            (onLazyLoad)="load($event)" [totalRecords]="totalRecords"
            [globalFilterFields]="['fileName']"
            currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
            [showCurrentPageReport]=true [rowsPerPageOptions]="[10, 20,50,100]" styleClass="p-datatable-sm">

            <ng-template pTemplate="caption">
                <div class="row">
                    <!-- titre -->
                    <div class="col" style="padding: 8px; margin-left: 8px">
                        <span>Liste des publicités</span>
                    </div>

                    <!-- volet recherche -->
                    <div class="col text-right">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text"
                                (input)="dt.filterGlobal($any($event.target)!.value, 'contains')"
                                placeholder="Recherche..." />
                        </span>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>

                    <th pSortableColumn="fileName">Libellé
                        <p-sortIcon field="fileName"></p-sortIcon>
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-publicite>
                <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="publicite">
                    <!-- <td>{{document.id}} </td> -->
                    <td>{{publicite.fileName}} </td>

                    <td style="width:20%">

                        <app-actions-toolbal-iud
                            (info)="onInfo(publicite)" [enableBtnInfo]="enableBtnInfo"
                            (edit)="onEdit(publicite)" [enableBtnEdit]="enableBtnEdit"
                            (delete)="onDelete(publicite)" [enableBtnDelete]="enableBtnDelete"
                            (download)="voirPiece(publicite.url)" [enableBtnDownload]="enableBtnDownload"
                            >

                        </app-actions-toolbal-iud>

                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>


<p-dialog [(visible)]="showDialog" [modal]="true" [maximizable]="true" appendTo="body"
  [style]="{width: '50vw'}" [contentStyle]="{height: '100%'}"
  [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
  <ng-template pTemplate="header">
    <i class="{{isEditing() ? 'pi-pencil' : 'pi-plus'}} pi p-mr-1"></i>
    <span class="mr-auto">
      {{publicite.url ? 'Modifier' : 'Ajouter'}} une publicité
    </span>
  </ng-template>
  <p-progressBar *ngIf="isDialogOpInProgress" mode="indeterminate"></p-progressBar>
  <p-message *ngIf="dialogErrorMessage" severity="error" [text]="dialogErrorMessage"></p-message>
  <p-divider></p-divider>

  <!-- Form -->
  <form (ngSubmit)="save()" #dtf="ngForm">


    <div class="grid">

      <div class="col-12 md:col-6 lg:col-6">
        <div class="p-fluid p-col-12 col-md-12 mb-2">
          <label for="fileName">Libellé<span class="text-danger">*</span>:</label>
          <input id="fileName" name="fileName" #fileName="ngModel" [(ngModel)]="publicite.fileName" pInputText required />
        </div>

      </div>
    </div>
    <!--<div class="field col-12 md:col-12" *ngIf="publicite.id" >
      <p-fieldset legend="Image existant" [toggleable]="true">
        <table class="table table-sm">
          <thead>
          <tr>
            <th scope="col">Fichier</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{publicite.fileName}}</td>
            <td><button class="btn btn-success" (click)="voirPiece(publicite.url)">Visualiser</button></td>
          </tr>
          </tbody>
        </table>
      </p-fieldset>
    </div>-->


      <div class="p-fluid p-col-12 col-md-12 mb-2">
        <label for="url"> Url</label>
        <ng-container>

              <p-fileUpload name="demo" (onSelect)="onUpload($event)"
                            [multiple]="false" accept="image/png, image/gif, image/jpeg"  [maxFileSize]="10000000">
              </p-fileUpload>
        </ng-container>
      </div>

    <!-- </div> -->

    <p-divider></p-divider>
    <div class="p-ml-auto text-right">
      <button type="reset" label="Annuler" (click)="showDialog=false"
        class="p-button-raised p-button-text p-button-success mr-2" pButton>
      </button>
      <button type="submit" label="{{isEditing() ? 'Enregistrer' : 'Ajouter'}}"
        icon="pi {{isEditing() ? 'pi-save' : 'pi-plus'}}"
        class="p-button-raised p-button-text {{isEditing() ? 'p-button-primary' : 'p-button-success'}}"
        pButton>
      </button>
    </div>
  </form>
</p-dialog>





    <p-dialog [(visible)]="documentDetail" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
      <ng-template pTemplate="header">

        <span class="p-mr-auto"> DETAILS PUBLICITE</span>
      </ng-template>
      <div class="row">
        <div class="col-md-12">

            <li class="list-group-item list-group-item-action">
              <span class="va-m"><span class="font-weight-bold">Libellé:</span>  {{publicite.fileName}}</span>
            </li>

       </div>
        <p-divider></p-divider>
        <div class="field col-12 md:col-12" *ngIf="publicite.id" >
          <p-fieldset legend="Image" [toggleable]="true">
            <table class="table table-sm">
              <thead>
              <tr>
                <th scope="col">Fichier</th>
                <th scope="col">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{publicite.fileName}}</td>
                <td><button class="btn btn-success" (click)="voirPiece(publicite.url)">Visualiser</button></td>
              </tr>
              </tbody>
            </table>
          </p-fieldset>
        </div>
   </div>
   <p-divider></p-divider>
   <div class="p-ml-auto text-right">
       <button type="reset" label="Fermer" (click)="documentDetail=false" class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
   </div>
  </p-dialog>


  <app-confirmation></app-confirmation>
  </div>
