<div class="container">
  <div
    class="row d-flex justify-content-center"
    style="display: flex; width: 100%"
  >
    <!--<div
      class="col-12 mt-4 text-center"
      style="
        flex: 0 0 33.33%;
        margin-bottom: 20px;
        margin-top: 20px;
        padding: 10px;
      "
      *ngIf="publicites"
    >-->
    <div
      class="col-12 mt-4 text-center"
      style="margin-bottom: 20px; margin-top: 20px; padding: 10px"
      *ngIf="publicites"
    >
      <div id="custom-card">
        <h6
          style="
            border-width: 1px 0 0 0;
            padding: 1rem 1rem;
            font-weight: 500;
            text-align: center;
            font-size: 19px;
          "
          class="text-center"
        >
          Publicités
        </h6>

        <ngb-carousel>
          <ng-container *ngFor="let pub of publicites; let i = index">
            <ng-template ngbSlide id="{{ i }}">
              <img
                [src]="'data:image/jpeg;base64,' + pub.image"
                [alt]="pub.fileName"
              />
              <div class="carousel-caption" style="background-color: #0000007a">
                <h3>{{ pub.fileName }}</h3>
              </div>
            </ng-template>
          </ng-container>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>
<!-- nouveau -->
<div class="grid" style="background-color: rgb(248 248 248); width: 700">
  <div class="col-12 col-md-12">
    <div class="search-bar">
      <form (ngSubmit)="searchDocument(searchFilter)">
      <div class="input-group col-12 col-md-6 offset-md-3">
          <input
            type="search"
            placeholder="Recherche de documents..."
            id="value"
            name="value"
            #value="ngModel"
            [(ngModel)]="searchFilter.keyWord"
            class="form-control height-input"
            aria-describedby="basic-addon5"
          />
          <div class="input-group-prepend">
            <button
              class="btn btn-success height-input"
            >
              <i class="fa fa-search"></i><span> Rechercher</span>
            </button>
          </div>
      </div>
      </form>
    </div>
  </div>
</div>
<!-- ancien -->

<!-- <div class="grid" style="background-color: rgb(248 248 248); width: 700">
  <div class="col-12 col-md-12">
    <div class="search-bar">
      <div class="input-group col-12 col-md-6 offset-md-3">
        <input
          type="search"
          placeholder="Recherche de documents..."
          id="value"
          name="value"
          #value="ngModel"
          [(ngModel)]="searchFilter.keyWord"
          class="form-control height-input"
          aria-describedby="basic-addon5"
        />
        <div class="input-group-prepend">
          <button
            (click)="searchDocument(searchFilter)"
            class="btn btn-success height-input"
          >
            <i class="fa fa-search"></i><span> Rechercher</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container">
  <div class="row" style="display: flex; width: 100%">
    <!-- <div class="col-12 md:col-12 lg:col-3 mt-2" style="height:600px;">

        <p-card>
            <img src="assets/images/GV.webp" width="320" height="200"  id="accueil_r1_c1"  alt="GOUVERNEMENT" >
        </p-card>
    </div>-->
    <!--<div
      class="col"
      style="
        flex: 0 0 66.67%;
        padding: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
      "
    >-->
    <div class="col">
      <p-card [hidden]="actualiteHidden" class="custom-card">
        <ng-template pTemplate="header">
          <span>Documents récents</span>
        </ng-template>
        <div class="p-d-flex">
          <p-table
          #dt
          [value]="listeDocuments"
          [paginator]="false"
          [globalFilterFields]="[
            'reference',
            'date',
            'document',
            'secteur',
            'type',
            'description'
          ]"
          styleClass="p-datatable-sm"
        >
          <ng-template pTemplate="caption">
            <div class="row">
              <!-- titre -->
              <div class="col" style="padding: 8px; margin-left: 8px">
                <span>Liste des documents</span>
              </div>

              <!-- volet recherche -->
              <!-- <div class="col text-right">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      dt.filterGlobal($any($event.target)!.value, 'contains')
                    "
                    placeholder="Recherche..."
                  />
                </span>
              </div> -->
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="reference" style="width: 10%">
                Réf.
                <p-sortIcon field="reference"></p-sortIcon>
              </th>

              <th pSortableColumn="document">
                Document
                <p-sortIcon field="document"></p-sortIcon>
              </th>

              <th pSortableColumn="Objet">
                Objet
                <p-sortIcon field="Objet"></p-sortIcon>
              </th>
              <th pSortableColumn="type">
                Type
                <p-sortIcon field="Type"></p-sortIcon>
              </th>
              <th pSortableColumn="taille">
                Taille
                <p-sortIcon field="taille"></p-sortIcon>
              </th>
              <th>Actions</th>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="body"
            let-rowData
            let-rowIndex="rowIndex"
            let-document
          >
            <tr>
              <td style="max-width: 250px">
                <span class="word-wrap">{{ document.reference }}</span>
              </td>
              <td style="max-width: 250px">
                <img
                  style="height: 30px; margin-right: 5px"
                  src="assets/images/pdf-icon.png"
                />
                <span class="word-wrap" ngbTooltip="document.fileName">{{
                  shortenFileName(document.fileName, 30)
                }}</span>
              </td>
              <td style="max-width: 250px">
                <span class="word-break">{{ document.description }}</span></td>
              <td>{{ document.type?.libelle }}</td>

              <td style="width: 10%">{{ getSize(document) }} MB</td>

              <td style="width: 10%">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-eye"
                  label=""
                  pTooltip="Détail"
                  class="p-button-raised p-button-info mr-2"
                  (click)="getDetailDoc(document)"
                ></button>
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-download"
                  label=""
                  class="p-button-raised p-button-danger mr-2"
                  pTooltip="Telecharger"
                  (click)="onDownload(document.fileName)" 
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
          <div class="flex justify-content-center mt-2">
            <p-paginator
              (onPageChange)="onPageChange($event)"
              [first]="first"
              [rows]="itemsPerPage"
              [totalRecords]="totalItems"
              currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
              [showCurrentPageReport]=true
            ></p-paginator>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-12 col-md-6"   *ngFor="let document of documents.slice(0,4)" >
                    <p-panel class="p-card waves-effect hoverable">
                        <ng-template pTemplate="header">

                            <a type="button" (click)="onDownload(document.fileName)"><i pTooltip="telecharger" class="pi pi-file"></i>{{document.type ? document.type.libelle: '-'}} </a>
                        </ng-template>


                        <div class="card-title truncate"> {{document.secteur?.libelle}}</div>
                        <p class="card-rubrique">{{document.reference}}</p>
                        <p class="card-rubrique">{{document.description}}</p>




                        &lt;!&ndash; <div>
                            <a class="btn btn-success " type="submit" (click)="VoirEservice()"
                            >lien</a>
                        </div> &ndash;&gt;

                    </p-panel>
                </div>-->
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
            rel="stylesheet"
          />
          <div class="container">
            <div class="row" hidden>
              <div class="col-sm-4">
                <div class="panel panel-dark-outline tabs-panel">
                  <div class="panel-heading">
                    <ul class="nav nav-tabs pull-left type-document">
                      <li class="active mt-2">
                        <a
                          data-toggle="tab"
                          href=".documents-panel"
                          aria-expanded="true"
                        >
                          <i class="fa fa-file"></i> Types de documents</a
                        >
                      </li>
                    </ul>
                    <div class="clear"></div>
                  </div>
                  <div class="panel-body tab-content">
                    <div class="tab-pane active documents-panel">
                      <div class="clear"></div>
                      <div class="v-spacing-xs"></div>
                      <h4 class="no-margin-top">Dossiers</h4>
                      <ul class="folders list-unstyled">
                        <li *ngFor="let item of types">
                          <button
                            class="btn btn-link"
                            style="color: black"
                            (click)="test(item.libelle!)"
                          >
                            <i class="fa fa-folder" style="color: orange"></i>
                            {{ item.libelle?.toLocaleLowerCase() }}
                          </button>
                        </li>
                      </ul>
                      <div class="v-spacing-xs"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-md-8 col-sm-12 tab-content no-bg no-border"
              >
                <div class="mt-2">
                  Liste des documents de type : {{ typeDocSelected }}
                </div>
                <div
                  class="alert alert-warning text-center mt-2"
                  *ngIf="listeDocuments.length == 0"
                >
                  Aucun document trouvé dans cette section
                </div>
                <div class="tab-pane active documents documents-panel">
                  <button
                    class="document danger btn btn-link"
                    style="color: white"
                    *ngFor="let doc of listeDocuments"
                  >
                    <div
                      class="document-body"
                      (click)="getDetailDoc(doc)"
                      data-toggle="tooltip"
                      data-placement="top"
                      [title]="doc.fileName"
                    >
                      <i class="fa fa-file-pdf-o text-danger"></i>
                    </div>
                    <div
                      class="document-footer"
                      (click)="getDetailDoc(doc)"
                      data-toggle="tooltip"
                      data-placement="top"
                      [title]="doc.fileName"
                    >
                      <span class="document-name"> {{ doc.fileName }} </span>
                      <span class="document-description">
                        {{ getSize(doc) }} Mb
                      </span>
                    </div>
                    <!-- <div class="document-footer">
                          <span class="document-name"> {{doc.fileName}} </span>
                          <span class="document-description"> {{getSize(doc)}} Mb </span>
                        </div> -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>

    <!--<div class=" col-md-4 mt-2 text-center">
      <p-card>
        <ng-template pTemplate="header">
          <span>Publicités</span>
        </ng-template>

        <p-carousel [value]="publicites" [numVisible]="1" [numScroll]="1" [circular]="false"
          [responsiveOptions]="responsiveOptions" orientation="vertical" verticalViewPortHeight="360px">
          <ng-template let-publicite pTemplate="item">
            <div class="border-1 surface-border border-round m-2 text-center py-5 px-3"
              style="background-color: #FCFCFC; ">
              <div class="mb-3">
                <img src="{{ 'data:image/jpeg;base64,' +publicite.image }}" [alt]="publicite.name"
                  class="w-6 shadow-2" />
              </div>


            </div>
            <h5 style="color: #009e49;" class="mb-1">{{ publicite.fileName }}</h5>

          </ng-template>
        </p-carousel>

      </p-card>
    </div>-->
  </div>

  <!-- <div class="row" style="margin-bottom: 80px;">


    <div class=" col-md-4 mt-2 text-center flex: 0 0 33.33% padding: 10px;" *ngIf="publicites">
      <div id="custom-card">
        <h6 style="    border-width: 1px 0 0 0;
        padding: 1rem 1rem;
        font-weight: 500;
        text-align: center;
        font-size: 19px;" class="text-center">Publicités</h6>

        <ngb-carousel>
          <ng-container *ngFor="let pub of publicites; let i = index">
            <ng-template ngbSlide id="{{i}}">
              <img height="600" [src]="'data:image/jpeg;base64,' +pub.image" [alt]="pub.fileName">
              <div class="carousel-caption" style="    background-color: #0000007a;">
                <h3>{{ pub.fileName }}</h3>
              </div>
            </ng-template>
          </ng-container>
        </ngb-carousel>

      </div>
    </div>



  </div> -->
</div>
<!--<div class="row" style="background-color: rgb(197, 211, 199);">
    <div  class="col-12 md:col-12 lg:col-6 mt-2">
        <p class="title-style">
            Les documents les plus importants
        </p>
    </div>
 </div> -->
<!-- <div class="grid">
    <div  class="col-12 md:col-12 lg:col-6 mt-2">
        <p style="font-size:20px">
           <b>Constitutions</b>
        </p>

       <app-constitution></app-constitution>
    </div>
    <div  class="col-12 md:col-12 lg:col-6 mt-2">
        <p style="font-size:20px;">
            <b>Chartes</b>
        </p>
       <app-charte></app-charte>
    </div>
</div>-->

<!-- détails documents -->

<p-dialog
  [(visible)]="documentDetail"
  [modal]="true"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
>
  <ng-template pTemplate="header">
    <span class="p-mr-auto"> DETAILS DOCUMENT</span>
  </ng-template>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group list-group-flush">
        <li class="list-group-item list-group-item-action">
          <span class="va-m"
            ><span class="font-weight-bold">Type:</span>
            {{ documentSelected.type?.libelle }}</span
          >
        </li>
        <li class="list-group-item list-group-item-action">
          <span class="va-m"
            ><span class="font-weight-bold">reference:</span>
            {{ documentSelected.reference }}</span
          >
        </li>
        <li class="list-group-item list-group-item-action">
          <span class="va-m"
            ><span class="font-weight-bold">Date:</span>
            {{ documentSelected.date | date : "dd/MM/yyyy" }}</span
          >
        </li>

        <!-- <li class="list-group-item list-group-item-action">
          <span class="va-m"><span class="font-weight-bold">Secteur:</span> {{documentSelected.secteur?.libelle}}</span>
        </li> -->
        <li class="list-group-item list-group-item-action">
          <span class="va-m"
            ><span class="font-weight-bold">Objet:</span>
            {{ documentSelected.description }}</span
          >
        </li>
        <!-- <li class="list-group-item list-group-item-action">
          <span class="va-m"
            ><span class="font-weight-bold">
              JournalOfficiel:
              </span>
            {{ documentSelected.journalOfficiel?.numero }} du
            {{
              documentSelected.journalOfficiel?.dateJournal
                | date : "dd/MM/yyyy"
            }}
            - {{ documentSelected.journalOfficiel?.natureJournal }}-
            <a
              type="button"
              pTooltip="visiter le journal officiel"
              href="{{ documentSelected.journalOfficiel?.url }}"
              >{{ documentSelected.journalOfficiel?.url }}</a
            ></span
          >
        </li> -->
        <li class="list-group-item list-group-item-action">
          <span class="va-m"
            ><span class="font-weight-bold">Document parent:</span>
            {{ documentSelected.parent?.reference }}
          </span>
        </li>
      </ul>
    </div>
  </div>

  <!-- formulaire d'affichage du détail -->
  <p-divider></p-divider>
  <div class="p-ml-auto text-right">
    <button
      label="Fermer"
      (click)="fermer()"
      class="p-button-raised p-button-text p-button-success mr-2"
      pButton
    ></button>
    <button
      label="Telecharger le document"
      (click)="onDownload(documentSelected.fileName)"
      class="p-button-raised p-button-text p-button-success mr-2"
      pButton
    ></button>
  </div>
</p-dialog>
