<!-- l'entete de page -->
<div class="shadow-sm cesw-section cesw-section-img-tree cesw-section-img-contact card conseil-banner ">
    <div class="container">
        <div class="page-header">
            <div>
                <h1 class="page-title">Rapports du compte-rendu des ministres</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/"><i class="zmdi zmdi-home zmdi-hc-lg"></i></a></li>
                    <li class="breadcrumb-item active" aria-current="page"><i
                            style="margin-top: -10px;font-size: 20px;margin-right: 5px;" aria-hidden="true"
                            class="fa fa-home"></i>
                        <p-breadcrumb [model]="items"></p-breadcrumb>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-success text-center mt-8 mb-8 col-md-6 offset-3" style="height: 100%"><a
        href="https://sggcm.gov.bf/conseil-des-ministres/" target="_blank">Veuillez cliquez ici pour consulter les
        rapports sur le site officiel du SGG-CM </a></div>
<div class="mb-8">&nbsp;</div>
<div class="mb-8">&nbsp;</div>
<!-- details -->

<p-dialog [(visible)]="documentDetail" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}">
    <ng-template pTemplate="header">

        <span class="p-mr-auto"> DETAILS DOCUMENT</span>
    </ng-template>
    <div class="row">
        <div class="col-md-12">
            <ul class="list-group list-group-flush">

                <li class="list-group-item list-group-item-action">
                    <span class="va-m"><span class="font-weight-bold">Type:</span> {{document.type?.libelle}}</span>
                </li>
                <li class="list-group-item list-group-item-action">
                    <span class="va-m"><span class="font-weight-bold">reference:</span> {{document.reference}}</span>
                </li>
                <li class="list-group-item list-group-item-action">
                    <span class="va-m"><span class="font-weight-bold">Date:</span> {{document.date |
                        date:'dd/MM/yyyy'}}</span>
                </li>

                <!-- <li class="list-group-item list-group-item-action">
                    <span class="va-m"><span class="font-weight-bold">Secteur:</span>
                        {{document.secteur?.libelle}}</span>
                </li> -->
                <li class="list-group-item list-group-item-action">
                    <span class="va-m"><span class="font-weight-bold">Objet:</span> {{document.description}}</span>
                </li>
                <li class="list-group-item list-group-item-action">
                    <span class="va-m"><span class="font-weight-bold"> <a
                                pTooltip="visiter le journal officiel">JournalOfficiel:</a> </span>
                        {{document.journalOfficiel?.numero}} du {{document.journalOfficiel?.dateJournal |
                        date:'dd/MM/yyyy'}} - {{document.journalOfficiel?.natureJournal }}- <a type="button"
                            pTooltip="visiter le journal officiel"
                            href="{{document.journalOfficiel?.url}}">{{document.journalOfficiel?.url}}</a></span>
                </li>
                <li class="list-group-item list-group-item-action">
                    <span class="va-m"><span class="font-weight-bold">Document parent:</span>
                        {{document.parent?.reference}} </span>
                </li>

            </ul>
        </div>

    </div>
    <p-divider></p-divider>
    <div class="p-ml-auto text-right">
        <button type="reset" label="Fermer" (click)="documentDetail=false"
            class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
    </div>
</p-dialog>
