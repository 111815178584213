import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { LandingPageComponent } from 'src/app/theme/components/landing-page/landing-page.component';

const routes: Routes = [
  { path:'', redirectTo:'/accueil',pathMatch:'full'},

  { path: 'accueil', component: LandingPageComponent,
    children: [
     
     
  ]},


  { path: "**", redirectTo: '/accueil' }
];

@NgModule({
  declarations: [],
  imports: [
  CommonModule
  ]
})
export class PublicRoutingModule { }
