<style>
  .border-right-BF {
    border-right: 1px solid #8080804d;
  }
</style>

<div id="footer-top-section">
  <div class="container">
    <div class="row">
      <div class="text-center col-md-4 col-sm-12"><img src="../../../assets/images/legi-foooter-logo.png" height="150"
          alt="Burkina Faso" class="footer-logo-img">
      </div>
      <div class="col-md-4 col-sm-12">
        <h6>Liens utiles</h6>
        <ul class="list-unstyled mb-5 mb-lg-0">
          <li><a href="https://www.presidencedufaso.bf/"><i class="fa fa-link" aria-hidden="true"></i> La présidence du
              Faso</a></li>
          <li><a href="https://gouvernement.gov.bf/"><i class="fa fa-link" aria-hidden="true"></i> Le Gouvernement du
              Faso</a></li>
          <li><a href="https://www.sggcm.gov.bf"><i class="fa fa-link" aria-hidden="true"></i> Le SGGCM</a></li>
          <li><a href="https://servicepublic.gov.bf/"><i class="fa fa-link" aria-hidden="true"></i> Services Publics</a>
          </li>

          <li><a type="button" (click)="contacter()"><i class="fa fa-link" aria-hidden="true"></i> Contact</a></li>
        </ul>
      </div>
      <div class="col-md-4 col-sm-12">
        <h6>Infos contact</h6>
        <ul class="list-unstyled mb-5 mb-lg-0">
          <li><a href="#"><i class="fa fa-map-marker" aria-hidden="true"></i> 752 AVENUE BURKINA FASO</a></li>
          <li><a href="tel:+22625409974"><i class="fa fa-phone" aria-hidden="true"></i> (+226) 25 40 99 74</a></li>
          <li><a href="mailto:legiburkina@sggcm.gov.bf"><i class="fa fa-envelope" aria-hidden="true"></i>
              legiburkina@sggcm.gov.bf</a>
          </li>
          <li style="margin-top: 10px;"> <a href="https://www.facebook.com/sggcm" target="_blank">
              <i class="ion ion-social-facebook" style="color: #1877F2; margin-right: 10px;    font-size: 30px;"></i>
            </a> <a href="https://www.youtube.com/@sggcm" target="_blank">
              <i class="ion ion-social-youtube"></i>
            </a>
          </li>
          <li>
          </li>
        </ul>


      </div>
    </div>
  </div>
</div>


<div id="footer-bottom-section">
  <div class="container text-center">

    Copyright © <a href="https://www.sggcm.gov.bf/">SGG-CM</a> - Legiburkina 2024 | Tous les droits sont réservés.</div>

</div>

<!--<div class="container">
  <footer class="blog-footer">


    <div class="row small ">
      <div class="col-12 col-md-4" style="margin-bottom: 20px;">
        <img src="../../../assets/images/armoirie_BF.png" width="50" class="responsive-img" />
        <a href="https://sggcm.gov.bf/">
          <p>SECRETARIAT GENERAL DU GOUVERNEMENT ET DU CONSEIL DES MINISTRES </p>
        </a>
        <p>752 AVENUE BURKINA FASO - TEL: (+226) 25409974</p>

      </div>
      <div class="col-12 col-md-4 " style="margin-bottom: 20px;">
        <a type="button" (click)="accueil()">LEGIBURKINA</a>
        <br>
        <br>
        <br>
        <p>Copyright <b><a href="https://www.sggcm.gov.bf"><span>SGG-CM</span></a></b> &copy; {{ currentYear }}</p>
      </div>
      <div class="col-12 col-md-4" style="margin-bottom: 20px;">
        <a type="button" (click)="contacter()">NOUS CONTACTER</a>
        <p>
          <br>
          ADRESSE EMAIL : www.legiburkina@sggcm.gov.bf<br>
          <br>
          <a href="https://www.facebook.com/sggcm" target="_blank">
            <i class="ion ion-social-facebook"></i> FACEBOOK
          </a>
          <br>
          <a href="https://www.youtube.com/@sggcm" target="_blank">
            <i class="ion ion-social-youtube"></i> YOUTUBE
          </a>
          <br>


        </p>
      </div>

    </div>
  </footer>
</div>-->
