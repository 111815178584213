<div>
  <p-card>
  <!-- ToolBar -->

    <div class="p-d-flex">
      <app-crud-toolbar [enableCreate]="enableCreate" (create)="onCreate()">
      </app-crud-toolbar>
      <div class="p-ml-auto">
        <p-message *ngIf="message" [severity]="message.severity" [text]="message.summary"></p-message>
        <p-progressSpinner *ngIf="isOpInProgress" strokeWidth="4" [style]="{width: '50px', height: '50px'}">
        </p-progressSpinner>
      </div>
    </div>
      <p-divider></p-divider>

      <p-table #dt [value]="siteExternes" [paginator]="true" [rows]="recordsPerPage" [loading]="isLoading"
          (onLazyLoad)="load($event)" [totalRecords]="totalRecords"
          [globalFilterFields]="['libelle','siteExterne','Secteurs']"
          currentPageReportTemplate="Affichage {first} à {last} de {totalRecords} élements"
          [showCurrentPageReport]=true [rowsPerPageOptions]="[10, 20]" styleClass="p-datatable-sm">

          <ng-template pTemplate="caption">
              <div class="row">
                  <!-- titre -->
                  <div class="col" style="padding: 8px; margin-left: 8px">
                      <span>Liste des sites externes</span>
                  </div>

                  <!-- volet recherche -->
                  <div class="col text-right">
                      <span class="p-input-icon-left">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text"
                              (input)="dt.filterGlobal($any($event.target)!.value, 'contains')"
                              placeholder="Recherche..." />
                      </span>
                  </div>
              </div>
          </ng-template>

          <ng-template pTemplate="header">
              <tr>
                  <th pSortableColumn="libelle">Libelle
                      <p-sortIcon field="libelle"></p-sortIcon>
                  </th>
                 
                  <th pSortableColumn="siteExterne">Url
                      <p-sortIcon field="siteExterne"></p-sortIcon>
                  </th>
                
                  
                  <th>
                      Actions
                  </th>
              </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-siteExterne>
              <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="siteExterne">
                  <td>{{siteExterne.libelle}} </td>
                  <td>{{siteExterne.url}} </td>
                  <td>

                      <app-actions-toolbal-iud 
                          (info)="onInfo(siteExterne)" [enableBtnInfo]="enableBtnInfo"
                          (edit)="onEdit(siteExterne)" [enableBtnEdit]="enableBtnEdit" 
                          (delete)="onDelete(siteExterne)" [enableBtnDelete]="enableBtnDelete">
                      </app-actions-toolbal-iud>

                  </td>
              </tr>
          </ng-template>
      </p-table>
  </p-card>

<!-- formulaire d'ajout et de modification -->

<p-dialog [(visible)]="showDialog" [modal]="true" [maximizable]="true" appendTo="body"
  [style]="{width: '50vw'}" [contentStyle]="{height: '500px'}"
  [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
  <ng-template pTemplate="header">
    <i class="{{isEditing() ? 'pi-pencil' : 'pi-plus'}} pi p-mr-1"></i>
    <span class="mr-auto">
      {{siteExterne.id ? 'Modifier' : 'Ajouter'}} un site externe
    </span>
  </ng-template>
  <p-progressBar *ngIf="isDialogOpInProgress" mode="indeterminate"></p-progressBar>
  <p-message *ngIf="dialogErrorMessage" severity="error" [text]="dialogErrorMessage"></p-message>
  <p-divider></p-divider>
  <!-- Form -->
  <form (ngSubmit)="save()" #dtf="ngForm">



    <div class="p-fluid grid">
      <div class="p-fluid p-col-12 col-md-12">
        <label for="libelle">Libéllé<span class="text-danger">*</span></label>
        <input id="libelle" name="libelle" #libelle="ngModel" [(ngModel)]="siteExterne.libelle" pInputText required />

      </div>


      <div class="p-fluid p-col-12 col-md-12">
        <label for="url">url</label>
        <input id="url" name="url" #url="ngModel" [(ngModel)]="siteExterne.url" pInputText/>
      </div>
      <!-- <div class="p-fluid p-col-12 col-md-12">
        <label for="secteurs">Secteurs:</label>
        <p-dropdown [options]="secteurs"  optionLabel="libelle" [(ngModel)]="siteExterne.secteur" id="secteur" name="secteur" placeholder="Selectionner le Secteur"  row="4" imputId="secteur" [filter]="true" filterBy="label" [showClear]="true"></p-dropdown>
      </div> -->

    </div>

    <p-divider></p-divider>
    <div class="p-ml-auto text-right">
      <button description="reset" label="Annuler" (click)="showDialog=false"
        class="p-button-raised p-button-text p-button-success mr-2" pButton></button>
      <button description="submit" [disabled]="!dtf.form.valid" label="{{isEditing() ? 'Enregistrer' : 'Ajouter'}}"
        icon="pi {{isEditing() ? 'pi-save' : 'pi-plus'}}"
        class="p-button-raised p-button-text {{isEditing() ? 'p-button-primary' : 'p-button-success'}}"
        pButton></button>
    </div>
  </form>
</p-dialog>

<!-- formulaire d'affichage du détail -->


  <p-dialog [(visible)]="exempleDetail" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">

      <span class="p-mr-auto"> DETAILS SITES EXTERNES</span>
    </ng-template>
    <div class="row">
      <div class="col-md-12">
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-action">
            <span class="va-m"><span class="font-weight-bold">Libelle:</span>  {{siteExterne.libelle}}</span>
          </li>
          <li class="list-group-item list-group-item-action">
           <span class="va-m"><span class="font-weight-bold">Url:</span>  {{siteExterne.url}}</span>
         </li>

        </ul>
     </div>

 </div>
 <p-divider></p-divider>
 <div class="p-ml-auto text-right">
     <button description="reset" label="Fermer" (click)="exempleDetail=false" class="p-button-raised p-button-text p-button-success mr-2" pButton></button> 
 </div>
</p-dialog>

<!-- Confirmation -->
<app-confirmation></app-confirmation>
</div>
