import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import {DocumentService} from "../../../../services/parametrage/document.service";

@Component({
    selector: 'app-dhs-structure',
    templateUrl: './dhs-structure.component.html',
    styleUrls: ['./dhs-structure.component.scss']
})
export class DhsStructureComponent implements OnInit {

  data1: any[] = [];
  highcharts = Highcharts;
  chartOptions: any;
  countByType: { [key: string]: number } = {};
  countsByMonth: { [key: number]: number } = {};
  donnees: number[] = [];
    constructor(private documentService: DocumentService) {}

    ngOnInit(): void {
      this.getCountsByMonth();
      this.intialiser();
      this.loadCountByTypeDocument();
    }

  intialiser(): void{
    this.data1 = [
      {
        name: 'Nombre de telechargement par mois',
        data: [1,34,56,87,98,65,3,3,21,23,64,38]
      },
    ];

    this.chartOptions = {
      chart: {
        // type: "bar",
        type: 'column',
        column: {
          pointPadding: 0,
          borderWidth: 0,
          groupPadding: 0,
          shadow: false
        }
      },

      title: {
        text: "Nombre de telechargement par mois"
      },
      yAxis: {
        title: {
          text: "Téléchargement"
        }
      },
      xAxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      },
      series: this.data1
    };
  }


  loadCountByTypeDocument(): void {
    this.documentService.getCountByTypeDocument().subscribe(
      data => {
        this.countByType = data;
      },
      error => {
        console.error('Erreur lors du chargement des données', error);
      }
    );
  }

  getKeys(obj: { [key: string]: number }): string[] {
    return Object.keys(obj);
  }

  getCountsByMonth(): void {
    this.documentService.countDownloadsGroupedByMonth().subscribe(
      (data) => {
        this.countsByMonth = data;
        console.warn('Counts by Month:', this.countsByMonth);
      },
      (error) => {
        console.error('Error fetching counts by month:', error);
      }
    );
  }










}
