// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const commonAppURI: string = '/api/';

export const environment = {
  production: true,
  userRessource: commonAppURI+'statistiques/nombre-utilisateur-connecte',
  dhsDocumentRessource:commonAppURI+'statistiques/nombre-documents-telecharger-partype',
  dhsStatDocument:commonAppURI+'documents/statistique/downloadstate',
  contactRessource:commonAppURI+'notifications/contacteznous',
  imageResource: commonAppURI+'imagePublicitaires',
  documentResource: commonAppURI +'documents',
  globalSearchDocRessource: commonAppURI+'documents/search',
  searchDocRessource: commonAppURI+'documents/searchform',
  searchDocAutresRessource: commonAppURI+'documents/searchform',
  exempleResource: commonAppURI +'exemples',
  typeDocumentResource:commonAppURI+'type-document',
  secteursResource:commonAppURI +'secteur',
  journalOfficielResource:commonAppURI +'journalOfficiels',
  sitesExternesResource:commonAppURI+'sites-externes',
  agentResource: commonAppURI +'agents',
  resentEmailRessource:commonAppURI + 'account/send-activated-notification/',
  documentRessource:commonAppURI + 'documents',
  authenticationRessource: commonAppURI + 'authenticate',
  ministereRessource: commonAppURI+'ministeres',
  ministerStructureRessource:commonAppURI+'structures/change-ministere',
  privilegeRessource: commonAppURI+'privileges',
  profilRessource: commonAppURI + 'profiles',
  compteRessource: commonAppURI  ,
  activateResource:commonAppURI+'activate',
  structureRessource: commonAppURI + 'structures',
  passwordForgetRessource : commonAppURI +'account/reset-password-init',
  changePasswordRessource : commonAppURI +'account/change-password',

  recordsPerPage: 10,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
