import { GetAllNatureJournalResponse, JournalOfficiel, NatureJournal } from './../../models/parametrage/journal-officiel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GetAllJournalOfficielResponse } from 'src/app/models/parametrage/journal-officiel';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

const resourceUrl = environment.journalOfficielResource;


@Injectable({
  providedIn: 'root'
})
export class JournalOfficielService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<GetAllJournalOfficielResponse> {
    return this.http.get(resourceUrl, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let journalOfficielResponse: GetAllJournalOfficielResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          journalOfficiels: response.body as JournalOfficiel[]
        };
        return journalOfficielResponse;
      }));
  }

  getNatureJournal(): Observable<GetAllNatureJournalResponse> {
    return this.http.get(`assets/data/natureJournal.json`, { observe: 'response' })
    .pipe(map(response => {
        // eslint-disable-next-line prefer-const
        let natureJournals: GetAllNatureJournalResponse = {
          //totalCount: parseInt(response.headers.get(totalCountHeader)),
          natureJournals: response.body as NatureJournal[]
        };
        return natureJournals;
      }));
  }


  
  create(request: JournalOfficiel): Observable<JournalOfficiel> {
    return this.http.post(resourceUrl, request);
  }

  update(journalOfficiel: JournalOfficiel): Observable<JournalOfficiel> {
    return this.http.put(resourceUrl, journalOfficiel);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${resourceUrl}/${id}`);
  }

}
