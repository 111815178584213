import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { BadgeModule } from 'primeng/badge';
import { TagModule } from 'primeng/tag';
import { EditorModule } from 'primeng/editor';
import {CalendarModule} from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { SettingsRoutingModule } from './settings-routing.module';
import { AppCommonModule } from 'src/app/common/app-common.module';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar'; 
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputSwitchModule } from 'primeng/inputswitch';
import {CheckboxModule} from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StructureComponent } from './structure/structure.component';
import { MinistereStructureComponent } from './ministere-structure/ministere-structure.component';
import { AgentComponent } from './agent/agent.component';
import { ProfilComponent } from './profil/profil.component';
import { PrivilegeComponent } from './privilege/privilege.component';
import { CompteComponent } from './compte/compte.component';
import {InputMaskModule} from 'primeng/inputmask';
import {DropdownModule} from 'primeng/dropdown';
import { CompteValidationComponent } from './compte-validation/compte-validation.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NbCardModule, NbStepperModule, NbButtonModule, NbSelectModule, NbSpinnerModule, NbAutocompleteModule } from '@nebular/theme';

import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { ProfilUserComponent } from './profil-user/profil-user.component';
import {FileUploadModule} from 'primeng/fileupload';
import {AvatarGroupModule} from 'primeng/avatargroup';
import { ResetPasswordComponent } from 'src/app/theme/components/reset-password/reset-password.component';
import {PasswordModule} from 'primeng/password';
import { ExempleComponent } from './exemple/exemple.component';
import { SiteExterneComponent } from './site-externe/site-externe.component';
import { SecteursComponent } from './secteurs/secteurs.component';
import { JournalOfficielComponent } from './journal-officiel/journal-officiel.component';
import { TypeDocumentComponent } from './type-document/type-document.component';
import { DocumentComponent } from './document/document.component';
import { PubliciteComponent } from './publicite/publicite.component';
import {ChartModule} from 'primeng/chart';
import {FieldsetModule} from "primeng/fieldset";
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [
    StructureComponent,
    MinistereStructureComponent,
    AgentComponent,
    ProfilComponent,
    PrivilegeComponent,
    CompteComponent,
    CompteValidationComponent,
    ProfilUserComponent,
    ResetPasswordComponent,
    ExempleComponent,
    SecteursComponent,
    TypeDocumentComponent,
    DocumentComponent,
    JournalOfficielComponent,
    SiteExterneComponent,
    PubliciteComponent,
  ],
    imports: [
        ChartModule,
        CheckboxModule,
        CommonModule,
        FormsModule,
        NbButtonModule,
        NbCardModule,
        EditorModule,
        NbStepperModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        NbSelectModule,
        NbAutocompleteModule,
        CardModule,
        PasswordModule,
        TabMenuModule,
        FileUploadModule,
        NbSpinnerModule,
        DropdownModule,
        ProgressBarModule,
        ConfirmDialogModule,
        DropdownModule,
        InputTextareaModule,
        ProgressSpinnerModule,
        ToolbarModule,
        PanelModule,
        InputNumberModule,
        TableModule,
        DialogModule,
        ButtonModule,
        ToastModule,
        InputTextModule,
        ContextMenuModule,
        MessageModule,
        DividerModule,
        AppCommonModule,
        SettingsRoutingModule,
        CalendarModule,
        InputSwitchModule,
        InputMaskModule,
        BadgeModule,
        TagModule,
        InputSwitchModule,
        DialogModule,
        NbButtonModule,
        TabMenuModule,
        CardModule,
        PaginatorModule,
        TranslateModule.forChild({
            extend: true
        }),
        FieldsetModule
    ],
  providers: [ConfirmationService,MessageService],
})
export class SettingsModule { }
