import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, Message } from 'primeng/api';
import { Secteurs } from 'src/app/models/parametrage/secteurs';
import { SiteExterne } from 'src/app/models/parametrage/site-externe';
import { SecteursService } from 'src/app/services/parametrage/secteurs.service';

import { SiteExterneService } from 'src/app/services/parametrage/site-externe.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './../../../../services/parametrage/authentication.service';

@Component({
  selector: 'app-site-externe',
  templateUrl: './site-externe.component.html',
  styleUrls: ['./site-externe.component.scss']
})
export class SiteExterneComponent implements OnInit {

  @ViewChild('dtf') form!: NgForm;

  timeoutHandle: any;
  totalRecords!: number;
  recordsPerPage = environment.recordsPerPage;
  siteExternes!: SiteExterne[];
  siteExterne: SiteExterne= {};
  secteurs!: Secteurs[];
  enableCreate = true;
  enableBtnInfo = true;
  enableBtnEdit = true;
  enableBtnDelete=true;
  isLoading!: boolean;
  isOpInProgress!: boolean;
  isDialogOpInProgress!: boolean;
  showDialog = false;
  exempleDetail: boolean=false;
  message: any;
  dialogErrorMessage: any;
  permissions: any;
  constructor(
    private siteExterneService: SiteExterneService,

    private secteursService: SecteursService,
    

    private confirmationService: ConfirmationService,
    private authService: AuthenticationService
 
  ) { }

  ngOnInit(): void {
    this.permissions=this.authService.getPrivilege();
    this.enableBtnDelete=this.authService.checkPermission(this.permissions, ['ROLE_ADMIN']);
    this.load();
    this.getSecteurs();
  }

  isEditing() {
    return !!this.siteExterne.id;
  }
 // Affichage

  load(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.siteExterneService.getAll().subscribe(response => {
      this.isLoading = false;
      this.siteExternes = response.siteExternes;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
  getSecteurs(event?: LazyLoadEvent) {
    this.isLoading = true;
    this.secteursService.getAllSecteurs().subscribe(response => {
      this.isLoading = false;
      this.secteurs = response.listeSecteurs;

    }, error => {
      this.message = { severity: 'error', summary: error.error };
      console.error(JSON.stringify(error));
    });
  }
  //Creation

  save() {
    if (this.siteExterne.id) {
      this.edit();
    } else {
      this.create();
    }
  }

  onCreate() {
    this.siteExterne = {};
    this.clearDialogMessages();
    this.form.resetForm();
    this.showDialog = true;
  }

  create() {
    this.clearDialogMessages();
    this.isDialogOpInProgress = true;
    this.siteExterneService.create(this.siteExterne).subscribe(response => {
      if (this.siteExternes.length !== this.recordsPerPage) {
        this.siteExternes.push(response);
        this.siteExternes = this.siteExternes.slice();
      }
      this.totalRecords++;
      this.isDialogOpInProgress = false;
      this.showDialog = false;
      this.showMessage({ severity: 'success', summary: 'exemple créé avec succès' });
    }, error => this.handleError(error));
  }
  
  //Détail
  onInfo(selection: any) {
    this.siteExterne = Object.assign({}, selection);
    this.clearDialogMessages();
    this.exempleDetail=true;
  }

    // Edit

    onEdit(selection: any) {
      this.siteExterne = Object.assign({}, selection);
      this.clearDialogMessages();
      this.showDialog = true;
    }
  
    edit() {
      this.clearDialogMessages();
      this.isDialogOpInProgress = true;
      this.siteExterneService.update(this.siteExterne).subscribe(response => {
        let index = this.siteExternes.findIndex(exemple => exemple.id === response.id);
        this.siteExternes[index] = response;
        this.isDialogOpInProgress = false;
        this.showDialog = false;
        this.showMessage({ severity: 'success', summary: 'exemple modifié avec succès' });
      }, error => this.handleError(error));
    }

    
    // Deletion

  onDelete(selection: any) {
    this.confirmationService.confirm({
      message: 'Etes-vous sur de vouloir supprimer cet exemple?',
      accept: () => {
        this.delete(selection);
      }
    });
  }

  delete(selection: any) {
    this.isOpInProgress = true;
    this.siteExterneService.delete(selection.id).subscribe(() => {
      this.siteExternes = this.siteExternes.filter(siteExterne => siteExterne.id !== selection.id);
      selection = null;
      this.isOpInProgress = false;
      this.totalRecords--;
      this.showMessage({
        severity: 'success',
        summary: 'Exemple supprimé avec succès',
      });
    },(error) => this.handleError(error)
  );
  }
    // Errors

    handleError(error: HttpErrorResponse) {
      console.error(`Processing Error: ${JSON.stringify(error)}`);
      this.isDialogOpInProgress = false;
      this.dialogErrorMessage = error.error.title;
    }
     // Messages

  clearDialogMessages() {
    this.dialogErrorMessage = null;
  }

  showMessage(message: Message) {
    this.message = message;
    this.timeoutHandle = setTimeout(() => {
      this.message = null;
    }, 5000);
  }

}
